.chip {
    // Vars

    // Support

    // Module
    & {
        & {
            display: inline-flex;
            font-size: $fz--x-medium;
            line-height: 1;
            border-radius: 14px;
            background-color: $c--brand-very-light-blue;
            padding: 6px 12px 5px;
            margin: 0 12px 12px 0;
            color: $c--brand-primary;
            transition: color, background-color $trs--base;

            &:first-child {
                margin-left: 0;
            }

            @include has-focus() {
                background-color: $c--brand-secondary;
                color: $c--monochrome-white;
            }
        }
    }

    // Facets
    &.chip__active {
        background-color: $c--brand-secondary;
        color: $c--monochrome-white;
    }

    // States
}
