.team-list {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0 120px;

            @include mq('<=md') {
                padding: 30px 0;
            }
        }

        .team-list--title.team-list--title {
            margin-bottom: 16px;

            @include mq('<=md') {
                margin-bottom: 8px;
            }
        }

        .team-list--list {
            justify-content: flex-start;
        }

        .team-list--col {
            display: block;
            margin: 16px 0;

            @include mq('>md') {
                &:nth-child(3n+2) {
                    position: relative;
                    top: 80px;
                }
            }

            @include mq('<=md') {
                &:nth-child(2n) {
                    position: relative;
                    top: 60px;
                }
            }

            @include mq('<=sm') {
                &:nth-child(2n) {
                    position: relative;
                    top: unset;
                }
            }

            @include grid--span((ratio: 4/12));
            @include grid--span((ratio: 6/12, mq: '<=md'));
            @include grid--span((ratio: 12/12, mq: '<=sm'));
        }
    }

    // Facets

    // States
}
