.article-header {
    // Vars

    // Support

    // Module
    & {
        & {
            padding-top: 40px;
            margin-bottom: -40px;

            @include mq('<=md') {
                padding-top: 30px;
                margin-bottom: -30px;
            }
        }

        .article-header--title {
            @include type--h1;

            margin-bottom: 32px;
        }

        .article-header--row {
            justify-content: center;
        }

        .article-header--col {
            @include grid--span((ratio: 8/12));
            @include grid--span((ratio: 12/12, mq: '<=md'));
        }

        .article-header--visual-col {
            margin-bottom: 60px;

            @include mq('<=md') {
                margin-bottom: 40px;
            }
        }

        .article-header--share-wrapper {
            margin-bottom: 24px;
        }

        .article-header--visual {
            width: 100%;
            height: 100%;
        }

        .article-header--lead {
            @include type--lead;

            margin-bottom: 0;

            @include mq('<=md') {
                margin-bottom: 0;
            }
        }
    }

    // Facets

    // States
}
