/// Field styles
///
@mixin form-field--form-select() {
    // Vars

    // Module
    & {
        .form-field--input {
            //padding: 15px 12px;

            @include placeholder() {
                color: $c--brand-blue-grey;
            }

            .form-field--title-label {
                cursor: pointer;
                transition: $trs--common-props;
            }

            .form-field--input-option {
                @include ff--base-regular();
                color: $c--brand-dark-blue;
                background: rgba($c--monochrome-white, 0.5);

                @include has-focus() {
                    background-color: $c--monochrome-light-gray;
                }
            }
        }

        .form-field--action.form-field--action {
            pointer-events: none;
            padding: 0;
        }
    }

    // States
    & {
        // Base
        @include form-field--apply-states(()) {
            @include form-field--form-select-base();
        }

        // Focus
        @include form-field--apply-states(('disabled': false, 'focus': true)) {
            @include form-field--form-select-focus();
        }

        // Disabled
        @include form-field--apply-states(('disabled': true, 'focus': default)) {
            @include form-field--form-select-disabled();
        }

        // Empty
        @include form-field--apply-states(('filled': false)) {
            @include form-field--form-select-empty();
        }

        // Filled
        @include form-field--apply-states(('filled': true)) {
            @include form-field--form-select-filled();
        }

        // Empty and disabled
        @include form-field--apply-states(('filled': false, 'disabled': true)) {
            @include form-field--form-select-empty-disabled();
        }
    }
}

/// Base
///
@mixin form-field--form-select-base() {
    .form-field--input {
        cursor: pointer;
    }

    .form-field--action.form-field--action__arrow {
        color: $c--monochrome-dark-gray;
    }
}

/// Focus
///
@mixin form-field--form-select-focus() {
    .form-field--input {
        padding-bottom: 6px;
    }

    .form-field--title-label {
        color: $c--brand-primary;
    }

    .form-field--action.form-field--action__arrow {
        color: $c--monochrome-silver;
    }
}

/// Disabled
///
@mixin form-field--form-select-disabled() {
    .form-field--action.form-field--action__arrow {
        color: $c--signal-disabled;
        cursor: default;
    }

    .form-field--input {
        color: $c--signal-disabled;
    }
}

/// Filled
///
@mixin form-field--form-select-filled() {
    .form-field--input {
        color: $c--brand-primary;
    }
}

/// Empty
///
@mixin form-field--form-select-empty() {
    .form-field--input {
        color: $c--brand-blue-grey;
    }
}

/// Empty and disabled
///
@mixin form-field--form-select-empty-disabled() {
    .form-field--input {
        color: transparent;
    }
}
