.nav-panel-content {
    // Vars

    // Support

    // Module
    & {
        & {
            margin-top: 16px;
            padding-top: 16px;
            padding-bottom: 12px;

            @include mq('md') {
                padding-top: 24px;
                padding-bottom: 24px;
            }
        }

        .nav-panel-content--overview-btn {
            width: 100%;
            margin-bottom: 16px;
        }

        .nav-panel-content--child {
            margin-left: 10px;
        }

        .nav-panel-content--direct-link {
            padding: 16px 0;
        }

        .nav-panel-content--item-link {
            color: $c--monochrome-white;

            &.nav-panel-content--item-link__active {
                color: $c--brand-secondary;
            }
        }

        .nav-panel-content--container.nav-panel-content--container {
            max-width: unset;
            padding: 0 40px;

            @include mq('<=sm') {
                padding: 0 24px;
            }
        }
    }

    // Facets
    &.nav-panel-content__side-nav {
        .nav-panel-content--child-wrapper {
            padding: 0 12px;
        }

        .nav-panel-content--child {
            margin-left: 32px;

            @include mq('<=sm') {
                margin-left: 22px;
            }
        }
    }

    // States
}
