/// Field styles
///
@mixin form-field--primary-has-actions($sz--icon-margin, $sz--icon-offset, $sz--icon-width, $sz--padding-top, $sz--padding-x, $sz--padding-left, $sz--padding-bottom) {
    // Vars
    $c--action-focus: $c--label-text;
    $num--max-actions: 3;

    // Support

    // Module
    & {
        .form-field--input-container {
            // Padding right for the actions, depending on the action count
            // Limit action count to 3, that should be enough for every case...
            @for $i from 1 through $num--max-actions {
                &[data-action-count='#{$i}'] {
                    .form-field--input {
                        padding-right: $sz--icon-offset + ($sz--icon-margin * 2) + ($i * $sz--icon-width);
                        line-height: $lh--medium;
                    }
                }
            }
        }

        .form-field--actions {
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;

            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            height: 100%;
            padding-right: $sz--icon-margin;
            padding-left: $sz--padding-x;
        }

        .form-field--action {
            pointer-events: auto;

            position: relative;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;

            width: $sz--icon-width;
            height: 100%;

            cursor: pointer;
            transition: color $trs--base;
        }
    }

    // States
}
