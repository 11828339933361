.payment-list {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0;

            @include mq('<=md') {
                padding: 30px 0;
            }
        }

        .payment-list--container.payment-list--container {
            overflow: visible;
        }

        .payment-list--row.payment-list--row {
            overflow: visible;
        }

        .payment-list--header-row {
            justify-content: space-between;
            align-items: center;

            @include mq('<=md') {
                margin-bottom: 20px;
            }
        }

        .payment-list--title-col {
            @include grid--span((ratio: 7/12, mq: '>sm'));

            @include mq('<=sm') {
                margin-bottom: 24px;
            }

            h2 {
                @include type--h2;
                margin-bottom: 0;

                @include mq('<=md') {
                    margin-bottom: 0;
                }
            }
        }

        .payment-list--filter-col {
            @include grid--span((ratio: 3/12));
            @include grid--span((ratio: 4/12, mq: 'lg'));
            @include grid--span((ratio: 5/12, mq: 'md'));
            @include grid--span((ratio: 3/4, mq: '<=sm'));
        }

        .payment-list--filter {
            width: 100%;
        }

        .payment-list--item {
            display: flex;
            justify-content: center;
            margin: 40px 0;

            @include grid--span((ratio: 2.4/12));
            @include grid--span((ratio: 3/12, mq: '<=md'));
            @include grid--span((ratio: 6/12, mq: '<=sm'));

            @media screen and (max-width: 350px) {
                flex: 0 0 100%;
            }

            @include mq('<=md') {
                margin: 20px 0;
            }
        }
    }

    // Facets

    // States
}
