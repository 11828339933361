.trs-accordion {
    // Vars
    $trs--generic-page-fade-speed: 2000ms;
    $trs--generic-page-fade-fn: ease;
    $trs--generic-page-fade: $trs--generic-page-fade-speed $trs--generic-page-fade-fn;

    // Support

    // Module

    // Facets

    // States
    &-enter-active, &-leave-active {
        transition: transition-props($trs--generic-page-fade-speed $trs--generic-page-fade-fn, opacity);
    }

    &-enter, &-leave-to {
        opacity: 0;
    }
}
