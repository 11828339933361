.dat-loader {
    // Vars
    $animation-duration: 5400ms;

    $size: 10px;
    $margin: 3px;
    $next: $size + $margin;

    $loader-size: ($size * 3) + ($margin * 2);
    // Support

    // Module
    & {
        & {
            position: relative;
            width: $loader-size;
            height: $loader-size;
            margin: 40px 0;

            @include mq('<=md') {
                margin: 30px 0;
            }

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                display: block;
                width: $size;
                height: $size;
                box-shadow: $next 0 0 $c--monochrome-white,
                    ($next * 2) 0 0 $c--monochrome-white,
                    0 (0 - $next) 0 $c--monochrome-white,
                    $next (0 - $next) 0 $c--monochrome-white,
                    ($next * 2) (0 - $next) 0 $c--monochrome-white,
                    0 (0 - ($next * 2)) $c--monochrome-white,
                    $next (0 - ($next * 2)) $c--monochrome-white,
                    ($next * 2) (0 - ($next * 2)) rgba(33, 61, 98, 0);
                animation: loader $animation-duration linear forwards infinite;
                transform: translate3d(0, 0, 0);
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 10px;
                left: 0;
                display: block;
                width: $size;
                height: $size;
                background-color: $c--brand-secondary;
                opacity: 0;
                animation: base $animation-duration linear forwards infinite;
                transform: translate3d(0, 0, 0);
            }
        }
    }

    // Facets

    // States

    @keyframes base {
        0% {
            bottom: 10px;
            opacity: 0;
        }

        5%, 50% {
            bottom: 0;
            opacity: 1;
        }

        55%, 100% {
            bottom: -10px;
            opacity: 0;
        }
    }

    @keyframes loader {
        0% {
            box-shadow: $next -10px $c--monochrome-white,
                ($next * 2) 0 $c--monochrome-white,
                0 (0 - $next) $c--monochrome-white,
                $next (0 - $next) $c--monochrome-white,
                ($next * 2) (0 - $next) $c--monochrome-white,
                0 (0 - ($next * 2)) $c--monochrome-white,
                $next (0 - ($next * 2)) $c--monochrome-white,
                ($next * 2) (0 - ($next * 2)) rgba(33, 61, 98, 0);
        }

        5% {
            box-shadow: $next -10px $c--monochrome-white,
                ($next * 2) 0 $c--monochrome-white,
                0 (0 - $next) $c--monochrome-white,
                $next (0 - $next) $c--monochrome-white,
                ($next * 2) (0 - $next) $c--monochrome-white,
                0 (0 - ($next * 2)) $c--monochrome-white,
                $next (0 - ($next * 2)) $c--monochrome-white,
                ($next * 2) (0 - ($next * 2)) rgba(33, 61, 98, 0);
        }

        10% {
            box-shadow: $next 0 $c--brand-primary,
                ($next * 2) -10px $c--monochrome-white,
                0 (0 - $next) $c--monochrome-white,
                $next (0 - $next) $c--monochrome-white,
                ($next * 2) (0 - $next) $c--monochrome-white,
                0 (0 - ($next * 2)) $c--monochrome-white,
                $next (0 - ($next * 2)) $c--monochrome-white,
                ($next * 2) (0 - ($next * 2)) rgba(33, 61, 98, 0);
        }

        15% {
            box-shadow: $next 0 $c--brand-primary,
                ($next * 2) 0 $c--brand-primary,
                0 (0 - $next - 10px) $c--monochrome-white,
                $next (0 - $next) $c--monochrome-white,
                ($next * 2) (0 - $next) $c--monochrome-white,
                0 (0 - ($next * 2)) $c--monochrome-white,
                $next (0 - ($next * 2)) $c--monochrome-white,
                ($next * 2) (0 - ($next * 2)) rgba(33, 61, 98, 0);
        }

        20% {
            box-shadow: $next 0 $c--brand-primary,
                ($next * 2) 0 $c--brand-primary,
                0 (0 - $next) $c--brand-primary,
                $next (0 - $next - 10px) $c--monochrome-white,
                ($next * 2) (0 - $next) $c--monochrome-white,
                0 (0 - ($next * 2)) $c--monochrome-white,
                $next (0 - ($next * 2)) $c--monochrome-white,
                ($next * 2) (0 - ($next * 2)) rgba(33, 61, 98, 0);
        }

        25% {
            box-shadow: $next 0 $c--brand-primary,
                ($next * 2) 0 $c--brand-primary,
                0 (0 - $next) $c--brand-primary,
                $next (0 - $next) $c--brand-primary,
                ($next * 2) (0 - $next - 10px) $c--monochrome-white,
                0 (0 - ($next * 2)) $c--monochrome-white,
                $next (0 - ($next * 2)) $c--monochrome-white,
                ($next * 2) (0 - ($next * 2)) rgba(33, 61, 98, 0);
        }

        30% {
            box-shadow: $next 0 $c--brand-primary,
                ($next * 2) 0 $c--brand-primary,
                0 (0 - $next) $c--brand-primary,
                $next (0 - $next) $c--brand-primary,
                ($next * 2) (0 - $next) $c--brand-primary,
                0 (0 - ($next * 2) - 10px) $c--monochrome-white,
                $next (0 - ($next * 2)) $c--monochrome-white,
                ($next * 2) (0 - ($next * 2)) rgba(33, 61, 98, 0);
        }

        35% {
            box-shadow: $next 0 $c--brand-primary,
                ($next * 2) 0 $c--brand-primary,
                0 (0 - $next) $c--brand-primary,
                $next (0 - $next) $c--brand-primary,
                ($next * 2) (0 - $next) $c--brand-primary,
                0 (0 - ($next * 2)) $c--brand-primary,
                $next (0 - ($next * 2) - 10px) $c--monochrome-white,
                ($next * 2) (0 - ($next * 2)) rgba(33, 61, 98, 0);
        }

        40% {
            box-shadow: $next 0 $c--brand-primary,
                ($next * 2) 0 $c--brand-primary,
                0 (0 - $next) $c--brand-primary,
                $next (0 - $next) $c--brand-primary,
                ($next * 2) (0 - $next) $c--brand-primary,
                0 (0 - ($next * 2)) $c--brand-primary,
                $next (0 - ($next * 2)) $c--brand-primary,
                ($next * 2) (0 - ($next * 2) - 10px) rgba(33, 61, 98, 0);
        }

        45%, 55% {
            box-shadow: $next 0 $c--brand-primary,
                ($next * 2) 0 $c--brand-primary,
                0 (0 - $next) $c--brand-primary,
                $next (0 - $next) $c--brand-primary,
                ($next * 2) (0 - $next) $c--brand-primary,
                0 (0 - ($next * 2)) $c--brand-primary,
                $next (0 - ($next * 2)) $c--brand-primary,
                ($next * 2) (0 - ($next * 2)) $c--brand-primary;
        }

        60% {
            box-shadow: $next 10px $c--monochrome-white,
                ($next * 2) 0 $c--brand-primary,
                0 (0 - $next) $c--brand-primary,
                $next (0 - $next) $c--brand-primary,
                ($next * 2) (0 - $next) $c--brand-primary,
                0 (0 - ($next * 2)) $c--brand-primary,
                $next (0 - ($next * 2)) $c--brand-primary,
                ($next * 2) (0 - ($next * 2)) $c--brand-primary;
        }

        65% {
            box-shadow: $next 10px $c--monochrome-white,
                ($next * 2) 10px $c--monochrome-white,
                0 (0 - $next) $c--brand-primary,
                $next (0 - $next) $c--brand-primary,
                ($next * 2) (0 - $next) $c--brand-primary,
                0 (0 - ($next * 2)) $c--brand-primary,
                $next (0 - ($next * 2)) $c--brand-primary,
                ($next * 2) (0 - ($next * 2)) $c--brand-primary;
        }

        70% {
            box-shadow: $next 10px $c--monochrome-white,
                ($next * 2) 10px $c--monochrome-white,
                0 (0 - $next + 10px) $c--monochrome-white,
                $next (0 - $next) $c--brand-primary,
                ($next * 2) (0 - $next) $c--brand-primary,
                0 (0 - ($next * 2)) $c--brand-primary,
                $next (0 - ($next * 2)) $c--brand-primary,
                ($next * 2) (0 - ($next * 2)) $c--brand-primary;
        }

        75% {
            box-shadow: $next 10px $c--monochrome-white,
                ($next * 2) 10px $c--monochrome-white,
                0 (0 - $next + 10px) $c--monochrome-white,
                $next (0 - $next + 10px) $c--monochrome-white,
                ($next * 2) (0 - $next) $c--brand-primary,
                0 (0 - ($next * 2)) $c--brand-primary,
                $next (0 - ($next * 2)) $c--brand-primary,
                ($next * 2) (0 - ($next * 2)) $c--brand-primary;
        }

        80% {
            box-shadow: $next 10px $c--monochrome-white,
                ($next * 2) 10px $c--monochrome-white,
                0 (0 - $next + 10px) $c--monochrome-white,
                $next (0 - $next + 10px) $c--monochrome-white,
                ($next * 2) (0 - $next + 10px) $c--monochrome-white,
                0 (0 - ($next * 2)) $c--brand-primary,
                $next (0 - ($next * 2)) $c--brand-primary,
                ($next * 2) (0 - ($next * 2)) $c--brand-primary;
        }

        85% {
            box-shadow: $next 10px $c--monochrome-white,
                ($next * 2) 10px $c--monochrome-white,
                0 (0 - $next + 10px) $c--monochrome-white,
                $next (0 - $next + 10px) $c--monochrome-white,
                ($next * 2) (0 - $next + 10px) $c--monochrome-white,
                0 (0 - ($next * 2) + 10px) $c--monochrome-white,
                $next (0 - ($next * 2)) $c--brand-primary,
                ($next * 2) (0 - ($next * 2)) $c--brand-primary;
        }

        90% {
            box-shadow: $next 10px $c--monochrome-white,
                ($next * 2) 10px $c--monochrome-white,
                0 (0 - $next + 10px) $c--monochrome-white,
                $next (0 - $next + 10px) $c--monochrome-white,
                ($next * 2) (0 - $next + 10px) $c--monochrome-white,
                0 (0 - ($next * 2) + 10px) $c--monochrome-white,
                $next (0 - ($next * 2) + 10px) $c--monochrome-white,
                ($next * 2) (0 - ($next * 2)) $c--brand-primary;
        }

        95%, 100% {
            box-shadow: $next 10px $c--monochrome-white,
                ($next * 2) 10px $c--monochrome-white,
                0 (0 - $next + 10px) $c--monochrome-white,
                $next (0 - $next + 10px) $c--monochrome-white,
                ($next * 2) (0 - $next + 10px) $c--monochrome-white,
                0 (0 - ($next * 2) + 10px) $c--monochrome-white,
                $next (0 - ($next * 2) + 10px) $c--monochrome-white,
                ($next * 2) (0 - ($next * 2) + 10px) rgba(33, 61, 98, 0);
        }
    }
}
