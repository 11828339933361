/// Field styles
///
@mixin form-field--primary-input($sz--padding-top, $sz--padding-x, $sz--padding-bottom, $sz--padding-left, $sz--border-width) {
    // Vars

    // Support

    // Module
    & {
        .form-field--input-container {
            position: relative;
            background-color: $c--monochrome-light-gray;
            border-radius: 3px;
        }

        .form-field--title-label {
            cursor: pointer;
            transition: $trs--common-props;
        }

        .form-field--input {
            display: inline-block;
            width: 100%;
            padding: $sz--padding-top $sz--padding-x $sz--padding-bottom $sz--padding-left;
            border-bottom: solid $sz--border-width;
            cursor: text;
            transition: $trs--common-props;
            line-height: $lh--medium;

            @include placeholder() {
                transition: $trs--common-props;
            }
        }
    }

    // States
    & {
        // Base
        @include form-field--apply-states(()) {
            @include form-field--primary-input-base();
        }

        // Focus
        @include form-field--apply-states(('disabled': false, 'focus': true)) {
            @include form-field--primary-input-focus();
        }

        // Disabled
        @include form-field--apply-states(('disabled': true, 'focus': default)) {
            @include form-field--primary-input-disabled();
        }

        // Error
        @include form-field--apply-states(('error': true, 'focus': default)) {
            @include form-field--primary-input-error();
        }
    }
}

/// Base
///
@mixin form-field--primary-input-base() {
    .form-field--input {
        color: $c--brand-primary;
        border-color: $c--monochrome-dark-gray;

        @include placeholder() {
            color: $c--brand-blue-grey;
        }
    }
}

/// Focus
///
@mixin form-field--primary-input-focus() {
    .form-field--title-label {
        color: $c--label-text;
    }

    .form-field--input {
        border-color: $c--brand-primary;
        border-bottom-width: 2px;
        color: $c--brand-primary;
        padding-bottom: 6px;

        @include placeholder() {
            color: $c--brand-blue-grey;
        }
    }
}

/// Disabled
///
@mixin form-field--primary-input-disabled() {
    .form-field--title-label {
        @include is-selectable(false);
        color: $c--brand-light-blue-gray;
        cursor: default;
    }

    .form-field--input {
        @include is-selectable(false);
        color: $c--brand-light-blue-gray;
        background-color: $c--monochrome-very-light-gray;
        border-color: $c--brand-light-blue-gray;
        cursor: default;

        @include placeholder() {
            color: transparent;
        }
    }
}

/// Error
///
@mixin form-field--primary-input-error() {
    .form-field--input {
        border-color: $c--signal-error;
    }

    .form-field--title-label {
        color: $c--signal-error;
    }
}
