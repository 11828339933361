.acquirer-list {
    // Vars

    // Support

    // Module
    & {
        & {
            margin: 10px 0;

            @include mq('>md') {
                padding: 0 20px;
            }
        }

        .acquirer-list--title {
            @include type--h3;
            margin-bottom: 24px;

            @include mq('<=md') {
                margin-bottom: 24px;
            }
        }

        .acquirer-list--item {
            padding: 12px 0;
            border-top: $divider-border;

            &:last-child {
                border-bottom: $divider-border;
            }
        }

        .acquirer-list--item-title {
            font-size: $fz--btn-medium;
            margin-bottom: 4px;
        }

        .acquirer-list--item-content {
            font-size: $fz--medium;
            line-height: $lh--medium;
            margin-bottom: 2px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .acquirer-list--col {
            @include grid--span((ratio: 12/12));
        }
    }

    // Facets

    // States
}
