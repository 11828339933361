/// Checks if all color variables are defined to easily access them
///
@mixin color--check-var-availability() {
    @each $map-key, $map-value in $color-maps {
        $map-name: $map-key;

        @if (type-of($map-value) == 'map') {
            @each $colors-key, $colors-value in $map-value {
                $color-variable-name: 'c--#{$map-name}-#{$colors-key}';

                @if (not variable-exists($color-variable-name)) {
                    @error 'Color `#{$color-variable-name}` was not defined. Please add it to the list of color variables.';
                }
            }
        }
    }
}

/// Looks through all color definitions and returns the stored value
/// @param {string} $subset - The subset in which the color is stored
/// @param {number|string} $idx - The key as a name or index value
///
@function color($subset, $idx) {
    $map: map-get($color-maps, $subset);
    $color: transparent;

    @if (type-of($idx) == 'number') {
        $color: nth($map, $idx);
    } @else {
        $color: map-get($map, $idx);
    }

    @if (type-of($color) != 'color') and (not list-contains((inherit initial null), $color)) {
        @error 'Color `#{$idx}` in map `#{$subset}` not found!';
    }

    @return $color;
}

@mixin background-opacity($color, $opacity: 1) {
    background: rgba($color, $opacity);
}
