.cookie-consent {
    // Vars

    // Support

    // Module
    & {
        .cookie-consent--row {
            justify-content: center;
        }

        .cookie-consent--col {
            @include grid--span((ratio: 8/12));
            @include grid--span((ratio: 12/12, mq: '<=md'));
        }

        .cookie-consent--success {
            margin: 24px auto;
        }

        .cookie-consent--success-icon {
            position: relative;
            top: 3px;
            margin-right: 8px;
        }

        .cookie-consent--success-message {
            color: $c--monochrome-dark-gray;
        }
    }

    // Facets

    // States
}
