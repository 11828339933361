.lang-list {
    // Vars

    // Support

    // Module
    & {
        .lang-list--link {
            margin: 0 12px;
            color: $c--brand-light-blue-gray;
            font-size: $fz--x-medium;
            line-height: 1;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    // Facets

    // States
}
