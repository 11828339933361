.form-submit {
    // Vars

    // Support

    // Module
    & {
        & {
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-end;
            width: 100%;
        }

        .form-submit--btn {
            width: 100%;
        }

        .form-submit--wrap {
            display: flex;
            flex-flow: column;
            align-items: flex-end;
            width: 100%;

            .grecaptcha-badge { // stylelint-disable-line plugin/selector-bem-pattern
                position: relative;
                box-shadow: none !important; // stylelint-disable-line declaration-no-important
            }

            > div {
                display: block;
                margin-bottom: 40px;
            }

            @include mq('<=lg') {
                > div {
                    flex-flow: column nowrap;
                    align-items: flex-end;
                    margin-bottom: 30px;
                }
            }

            @include mq('>lg') {
                > div {
                    flex-flow: row nowrap;
                    justify-content: space-between;
                    align-items: flex-start;
                }
            }
        }
    }

    // Facets

    // States
}
