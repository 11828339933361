.teaser-card {
    // Vars

    // Support

    // Module
    & {
        & {
            position: relative;

            @include mq('<=sm') {
                padding-top: 50px;
            }

            @include grid--root();
        }

        .teaser-card--mobile-header {
            position: absolute;
            top: 0;
            left: 50%;
            width: 100px;
            height: 100px;
            border-radius: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            transform: translate3d(-50%, -50%, 0);
            overflow: hidden;

            @include mq('>sm') {
                display: none;
            }
        }

        .teaser-card--col {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            overflow: hidden;
            position: relative;

            @include grid--span((ratio: 4/8));
        }

        .teaser-card--right-pane {
            &::before {
                content: '';
                z-index: z-index('teaser-card-triangle');
                position: absolute;
                width: 100%;
                height: 100%;
                transform: skew($skew) translateX(calc(-100% + #{$hover-reveal}));
                transform-origin: left bottom;
                transition: transform $trs--base;
            }
        }
    }

    // Facets
    &.teaser-card__specialist {
        .teaser-card--left-pane {
            @include grid--span((ratio: 5/8));
            @include grid--span((ratio: 8/8, mq: '<=sm'));
        }

        .teaser-card--right-pane {
            @include grid--span((ratio: 3/8));

            @include mq('<=sm') {
                display: none;
            }
        }

        .teaser-card--right-pane {
            &::before {
                background-color: $c--monochrome-white;
            }
        }
    }


    // States
    .teaser-card--left-pane__active {
        .teaser-card--right-pane {
            &::before {
                transform: skew($skew) translateX(calc(-100% + #{$hover-reveal * 2}));
            }
        }
    }

    .teaser-card--right-pane__active {
        .teaser-card--right-pane {
            &::before {
                transform: skew($skew) translateX(-100%);
            }
        }
    }
}
