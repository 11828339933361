.overlay {
    // Vars

    // Support

    // Module
    & {
        .overlay--backdrop {
            z-index: z-index('overlay-backdrop');
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba($c--brand-dark-blue, 0.6);
        }

        .overlay--content {
            z-index: z-index('overlay-content');
            top: 0;
            width: 100%;
        }
    }

    // Facets
    &.overlay.overlay__dyn-contact-overlay {
        .overlay--content {
            z-index: z-index('top-overlay-content');
        }

        .overlay--backdrop {
            z-index: z-index('top-overlay-backdrop');
        }
    }

    &.overlay.overlay__dyn-payment-overlay {
        .overlay--content {
            z-index: z-index('top-overlay-content');
        }

        .overlay--backdrop {
            z-index: z-index('top-overlay-backdrop');
        }
    }

    &.overlay.overlay__dyn-testaccount-overlay {
        .overlay--content {
            z-index: z-index('top-overlay-content');
        }

        .overlay--backdrop {
            z-index: z-index('top-overlay-backdrop');
        }
    }

    // States
}
