&.content-card__nba-left {
    // Vars

    // Support

    // Module
    & {
        .content-card--wrapper {
            background-color: $c--brand-very-light-blue;
        }
    }

    // Facets

    // States
}
