.sample-conf {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0;

            @include mq('<=md') {
                padding: 30px 0;
            }
        }

        .sample-conf--title.sample-conf--title {
            margin-bottom: 16px;

            @include mq('<=md') {
                margin-bottom: 0;
            }
        }

        .sample-conf--card-row {
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            //height: 100%;

            @include mq('<=sm') {
                justify-content: center;
            }
        }

        .sample-conf--card-col {
            @include grid--span((ratio: 4/12));
            @include grid--span((ratio: 6/12, mq: '<=md'));
            @include grid--span((ratio: 3/4, mq: '<=sm'));
            @include grid--span((ratio: 4/4, mq: '<=xs'));

            margin-top: 24px;
        }

        .sample-conf--card-col.sample-conf--card-col__large {
            @include grid--span((ratio: 6/12));
            @include grid--span((ratio: 12/12, mq: '<=sm'));
        }

        .sample-conf--cta-row {
            margin-top: 32px;
            justify-content: center;
        }

        .sample-conf--cta-col {
            @include grid--span((ratio: 4/4, mq: '<=sm'));
        }
    }

    // Facets

    // States
}
