
////
/// Font inclusion and support library
////
/// Dependencies and components
///
@import 'font__vars';
@import 'font__helpers';
@import 'font__definitions';
@import url('//hello.myfonts.net/count/36ce02');

/// Font-face definitions
@include _font--create('#{$web-static}/fonts/app/#{$ff--base}/#{$ff--base-regular-filename}', ('woff2', 'woff', 'ttf')) {
    @include ff--base-regular($ff--base);
}

@include _font--create('#{$web-static}/fonts/app/#{$ff--base}/#{$ff--base-semi-bold-filename}', ('woff2', 'woff', 'ttf')) {
    @include ff--base-semi-bold($ff--base);
}
