.hamburger {
    & {
        position: relative;
        top: 2px;
    }

    &.hamburger__dark {
        .hamburger-inner {
            background-color: $c--brand-primary;

            &::before, &::after {
                background-color: $c--brand-primary;
            }
        }
    }
}

@import 'node_modules/hamburgers/_sass/hamburgers/hamburgers.scss';
