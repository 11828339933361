.payment-overlay-content {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 0 50px;

            @include mq('<=md') {
                padding: 0 24px;
            }
        }

        .payment-overlay-content--content.payment-overlay-content--content {
            overflow: hidden;

            @include mq('>md') {
                padding: 0 38px;
            }

            .richtext,
            .gallery {
                padding: 0 0 32px;
                margin-left: -32px;
                margin-right: -32px;

                @include mq('<=sm') {
                    margin: 0 -24px;
                }
            }

            .richtext--col { // due to django restrictions we need to do this here
                @include grid--span((ratio: 12/12));
            }
        }

        .payment-overlay-content--content-container.payment-overlay-content--content-container {
            @include mq('>md') {
                padding: 0 37px;
            }
        }

        .payment-overlay-content--link {
            display: flex;
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    // Facets
    &.payment-overlay-content__header {
        margin-top: 18px;
        margin-bottom: 18px;

        @include mq('<=md') {
            margin-top: 13px;
        }

        @include mq('<=sm') {
            margin-top: 24px;
        }
    }

    // States
}
