.mobile-navbar {
    // Vars

    // Support

    // Module
    & {
        & {
            transition: background-color $trs--base;
            background-color: $c--monochrome-white;
            color: $c--brand-primary;
            border-bottom: $divider-border;

            @include mq('<=sm') {
                border-top: none;
                border-bottom: $divider-border;
            }
        }

        .mobile-navbar--row {
            padding-top: 24px;
            padding-bottom: 23px;
            justify-content: space-between;
            align-items: center;

            @include mq('<=sm') {
                padding-top: 20px;
                padding-bottom: 20px;
            }
        }

        .mobile-navbar--brand-link {
            display: flex;
        }

        .mobile-navbar--toggle {
            cursor: pointer;
            color: $c--brand-primary;
        }
    }

    // Facets
    &.mobile-navbar__dark {
        & {
            background-color: $c--brand-primary;
            color: $c--monochrome-white;
            border-bottom: solid 1px $c--brand-blue-grey;

            @include mq('<=sm') {
                border-top: none;
            }
        }

        .mobile-navbar--toggle {
            color: $c--monochrome-white;
        }
    }

    // States
}
