.department {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0;

            @include mq('<=md') {
                padding: 30px 0;
            }
        }

        .department--row {
            @include mq('<=sm') {
                display: block;
            }
        }

        .department--visual-wrapper {
            overflow: hidden;
        }

        .department--title.department--title {
            margin-bottom: 32px;

            @include mq('<=md') {
                margin-bottom: 24px;
            }
        }

        .department--lead {
            @include type--lead;
            color: $c--brand-primary;

            @include mq('<=md') {
                margin-bottom: 24px;
            }
        }

        .department--description {
            @include type--copy;
        }

        .department--text-wrapper {
            @include grid--span((ratio: 12/12));
        }

        .department--visual-col {
            position: relative;
            display: flex;
            overflow: hidden;

            @include grid--span((ratio: 5/12));
            @include grid--span((ratio: 12/12, mq: '<=sm'));

            @include mq('<=sm') {
                margin-bottom: 24px;
            }
        }

        .department--description-col {
            @include grid--span((ratio: 7/12));
            @include grid--span((ratio: 12/12, mq: '<=sm'));
        }

        .department--description-wrapper-row.department--description-wrapper-row {
            height: 100%;

            @include mq('<=lg') {
                display: block;
            }
        }

        .department--description-wrapper.department--description-wrapper.department--description-wrapper {
            flex-direction: column;
            justify-content: space-between;
        }

        .department--contact-row {
            justify-content: flex-end;
        }

        .department--contact-col {
            @include grid--span((ratio: 4/12));
            @include grid--span((ratio: 12/12, mq: '<=lg'));
        }

        .department--contact-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }

        .department--contact-card {
            flex: 0 0 100%;

            @include grid--span((ratio: 5/12, mq: 'lg'));
            @include grid--span((ratio: 6/12, mq: 'md'));
            @include grid--span((ratio: 12/12, mq: 'sm'));
        }
    }

    // Facets
    &.department__portrait {
        .department--visual-wrapper {
            position: relative;
            width: 100%;
            padding-top: 100% / 3 * 4;
            overflow: hidden;
        }

        .department--visual {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
        }

        .department--visual-col {
            position: relative;
            display: block;
            overflow: hidden;
            height: 100%;

            @include at-root(html, '.ie11') {
                height: auto;
            }

            img {
                @supports (object-fit: cover) {
                    object-fit: cover;
                    object-position: center;
                }
            }
        }

        .department--contact-wrapper {
            flex-direction: row;
            justify-content: flex-end;
        }

        .department--contact-col {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-content: flex-end;

            @include grid--span((ratio: 7/7));
        }

        .department--contact-card {
            @include grid--span((ratio: 4/7));
            @include grid--span((ratio: 5/7, mq: 'lg'));
            @include grid--span((ratio: 7/7, mq: '<=md'));
        }
    }

    &.department__landscape {
        .department--visual-wrapper {
            position: relative;
            width: 100%;
            padding-top: 100% / 3 * 1;
            overflow: hidden;
        }

        .department--visual {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
        }

        .department--visual-col {
            display: block;

            @include grid--span((ratio: 12/12));
        }

        .department--description-col {
            margin-top: 32px;

            @include grid--span((ratio: 12/12));

            @include mq('<=md') {
                margin-top: 24px;
            }

            @include mq('<=sm') {
                margin-top: 0;
            }
        }

        .department--description {
            @include mq('>lg') {
                margin-bottom: -24px;
            }
        }

        .department--text-wrapper {
            @include grid--span((ratio: 7/12));
            @include grid--span((ratio: 12/12, mq: '<=lg'));
        }

        .department--description-wrapper-row {
            justify-content: space-between;
        }

        .department--description-wrapper.department--description-wrapper {
            flex-direction: row;
        }

        .department--contact-wrapper {
            @include at-root(html, '.ie11') {
                position: absolute;
                bottom: 0;
                left: 0;
                display: block;
                width: 100%;
            }

            @include mq('<=lg') {
                @include at-root(html, '.ie11') {
                    position: relative;
                }
                flex-direction: row;
                justify-content: flex-start;
            }
        }

        .department--contact-col {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            @include at-root(html, '.ie11') {
                position: relative;
                display: block;
            }

            @include mq('<=lg') {
                display: block;
            }
        }
    }

    &.department__has-jobs {
        & {
            padding: 40px 0 80px;

            @include mq('<=md') {
                padding: 30px 0 60px;
            }
        }
    }

    // States
}
