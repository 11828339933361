.article-chips {
    // Vars

    // Support

    // Module
    & {
        & {
            margin-bottom: 10px;
        }
    }

    // Facets

    // States
}
