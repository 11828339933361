.skel {
    // vars
    //

    // base
    & {
        /*       display: flex;
               flex-flow: column nowrap;
               width: 100%;
               min-height: 100%;
               */
        overflow: hidden;

        //.skel--header {}

        .skel--main {
            //flex: 1 0 auto;
        }

        //.skel--footer {}
        //.skel--root {}
    }

    &.skel__cms {
        margin-top: 46px;
    }

    &.skel__ecom {
        &::before {
            content: '';
            pointer-events: none;
            z-index: z-index('ecom-border');
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            border: solid 12px $c--brand-primary;

            @include mq('<=md') {
                z-index: z-index('ecom-border-mobile');
            }

            @include mq('<=sm') {
                border: none;
            }
        }
    }
}
