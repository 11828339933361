.nba-primary-content {
    // Vars

    // Support

    // Module
    & {
        & {
            position: relative;
            display: flex;
            justify-content: flex-end;
            padding: 60px 0;
            width: 100%;
            height: 100%;

            @include mq('<=md') {
                padding: 40px 0;
            }
        }

        .nba-primary-content--content-wrapper {
            z-index: z-index('nba-primary-content');
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;
            padding: 5px 0;

            max-width: 303px;

            @include mq('<=lg') {
                max-width: 234px;
            }

            @include mq('<=md') {
                max-width: 100%;
            }
        }

        .nba-primary-content--title-wrapper {
            margin-bottom: 12px;

            h2 {
                @include type--h2;
                margin-bottom: 0;

                @include mq('<=md') {
                    margin-bottom: 0;
                }
            }

            @include mq('<=sm') {
                padding: 0 24px;
            }
        }

        .nba-primary-content--statement {
            max-width: 100%;
            color: $c--brand-blue-grey;

            @include mq('<=sm') {
                padding: 0 24px;
            }
        }

        .nba-primary-content--background {
            pointer-events: none;
            z-index: z-index('nba-primary-background');
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: 328px;
            background-repeat: no-repeat;

            @include mq('<=md') {
                display: none;
            }

            @include mq('<=sm') {
                left: 50%;
                top: 0;
                display: block;
                border-radius: 100%;
                width: 100px;
                height: 100px;
                transform: translate3d(-50%, -50%, 0);
                background-position: center;
                background-size: cover;
            }
        }

        .nba-primary-content--content-col {
            z-index: z-index('nba-primary-content');
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;

            @include grid--span((ratio: 8/12, mq: '<=md'));
            @include grid--span((ratio: 12/12, mq: '<=sm'));
        }

        .nba-primary-content--link-list {
            @include mq('<=sm') {
                text-align: center;
                border-top: $divider-border;
                padding: 24px 0 0;
            }
        }

        .nba-primary-content--content-image {
            display: none;
            flex: 0 0 208px;
            overflow: hidden;
            width: 100%;
            height: 100%;
            max-width: 208px;
            max-height: 208px;
            margin-right: 12px;

            @include mq('<=md') {
                display: block;
            }

            @include mq('<=sm') {
                display: none;
            }
        }

        .nba-primary-content--image {
            width: 100%;
        }
    }

    // Facets
    &.nba-primary-content__nba-left {
        & {
            flex-direction: row-reverse;
            justify-content: flex-end;
            padding-left: 202px;

            @include mq('<=lg') {
                padding-left: 156px;
            }

            @include mq('<=md') {
                padding-left: 12px;
                padding-right: 40px;
            }
        }

        .nba-primary-content--background {
            background-position: -134px -90px;

            @include mq('<=sm') {
                background-position: center;
            }
        }
    }

    &.nba-primary-content__nba-right {
        & {
            justify-content: flex-end;
            padding-right: 202px;

            @include mq('<=lg') {
                padding-right: 156px;
            }

            @include mq('<=md') {
                padding-left: 40px;
                padding-right: 12px;
            }
        }

        .nba-primary-content--content-image {
            margin-left: 12px;
            margin-right: 0;
        }

        .nba-primary-content--background {
            background-position: calc(100% + 133px) calc(100% + 75px);

            @include mq('<=sm') {
                background-position: center;
            }
        }
    }

    // States
}
