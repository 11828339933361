.product-graphic-header {
    // Vars

    // Support

    // Module
    & {
        & {
            display: flex;
            padding: 20px 0;

            @include mq('<=md') {
                padding: 30px 0;
            }
        }

        .product-graphic-header--row {
            justify-content: flex-start;
        }

        .product-graphic-header--mobile-image {
            display: none;
            justify-content: center;
            width: 100%;

            @include mq('<=sm') {
                display: flex;
            }
        }

        .product-graphic-header--text-col {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            @include grid--span((ratio: 5/12));
            @include grid--span((ratio: 6/12, mq: '<=md'));
            @include grid--span((ratio: 12/12, mq: '<=sm'));
        }

        .product-graphic-header--image-col {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @include grid--span((ratio: 6/12));
            @include grid--span((ratio: 12/12, mq: '<=sm'));
        }

        .product-graphic-header--title {
            @include type--h1;
            margin-bottom: 12px;
            hyphens: manual;

            @include mq('<=md') {
                margin-bottom: 12px;
            }
        }

        .product-graphic-header--image,
        .product-graphic-header--image-mobile {
            width: 320px;
            height: 320px;

            @include mq('md') {
                width: 290px;
                height: 290px;
            }

            @include mq('<=sm') {
                width: 328px;
                height: 328px;
            }
        }

        .product-graphic-header--image {
            display: flex;

            @include mq('<=sm') {
                display: none;
            }
        }

        .product-graphic-header--image-mobile {
            display: none;
            margin-bottom: 24px;

            @include mq('<=sm') {
                display: block;
            }
        }

        .product-graphic-header--cta {
            display: inline-flex;

            @include mq('<=sm') {
                width: 100%;
            }
        }

        .product-graphic-header--description {
            max-width: 473px;
        }
    }

    // Facets

    // States
}
