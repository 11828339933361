.spinning-loader {
    // Vars

    // Support

    // Module
    & {
        margin: auto;

        .spinning-loader--svg {
            width: 100%;
            height: 100%;

            .spinning-loader--svg-path {
                fill: none;
                stroke: currentColor;
                stroke-width: 2px;
            }
        }
    }

    // Facets

    // States
}
