/// Secondary
///
@mixin btn--secondary() {
    // Vars

    // Support

    // Module

    // Facets

    // States
    & {
        // Base
        @include btn--apply-states(()) {
            @include btn--secondary-base();
        }

        // Focus
        @include btn--apply-states(('disabled': false, 'focus': true)) {
            @include btn--secondary-focus();
        }

        // Active
        @include btn--apply-states(('disabled': false, 'focus': default, 'active': true)) {
            @include btn--secondary-active();
        }

        // Disabled
        @include btn--apply-states(('disabled': true, 'focus': default)) {
            @include btn--secondary-disabled();
        }
    }
}

/// Base
///
@mixin btn--secondary-base() {
    border-radius: 3px;
    color: $c--brand-primary;
    background-color: transparent;
    border-color: $c--brand-primary;
    margin: 1px;
}

/// Focus
///
@mixin btn--secondary-focus() {
    color: $c--brand-dark-blue;
    border: solid 2px $c--brand-dark-blue;
    margin: 0;

    @include background-opacity($c--brand-primary, 0.1);
}

/// Active
///
@mixin btn--secondary-active() {
    color: $c--brand-dark-blue;
    border: solid 1px $c--brand-dark-blue;

    @include background-opacity($c--brand-primary, 0.2);
}

/// Disabled
///
@mixin btn--secondary-disabled() {
    color: $c--signal-disabled;
    border: solid 1px $c--signal-disabled;
}
