.article-meta {
    // Vars

    // Support

    // Module
    & {
        & {
            margin-bottom: 16px;
        }

        .article-meta--item {
            font-size: $fz--x-medium;
            line-height: 1;
            font-weight: $fw--semi-bold;

            border-right: $divider-border;
            padding-right: 12px;
            margin-right: 12px;
            color: $c--brand-blue-grey;
            white-space: nowrap;

            &:last-child {
                border-right: none;
            }

            @include mq('<=sm') {
                display: inline-block;
                margin-top: 6px;
                margin-bottom: 6px;
            }
        }
    }

    // Facets

    // States
}
