.partner-item {
    // Vars

    // Support

    // Module
    & {
        .partner-item--image {
            width: 140px;
            height: 60px;
            margin-bottom: 12px;

            // https://codepen.io/sosuke/pen/Pjoqqp #5B728F
            filter: invert(46%) sepia(16%) saturate(793%) hue-rotate(174deg) brightness(90%) contrast(90%);

            @include has-focus() {
                // $c--brand-secondary
                filter: invert(62%) sepia(83%) saturate(919%) hue-rotate(101deg) brightness(90%) contrast(89%);
            }
        }

        .partner-item--description {
            display: block;
            max-width: 100%;

            @include type--copy-secondary;
            margin-bottom: 0;

            @include mq('<=md') {
                font-size: $fz--small;
                line-height: $lh--small;
                margin-bottom: 0;
            }

            @include mq('<=sm') {
                margin-bottom: 0;
            }
        }
    }

    // Facets

    // States
}
