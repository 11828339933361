.usp-values {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0;

            @include mq('<=md') {
                padding: 30px 0;
            }
        }

        .usp-values--title {
            @include type--h2;
            margin-bottom: 32px;

            @include mq('<=md') {
                margin-bottom: 24px;
            }
        }

        .usp-values--entry {
            @include grid--span((ratio: 3/12));
            @include grid--span((ratio: 5/12, mq: 'lg'));
            @include grid--span((ratio: 6/12, mq: '<=md'));
            @include grid--span((ratio: 8/12, mq: '<=sm'));
            @include grid--span((ratio: 12/12, mq: '<=xs'));

            @include mq('<=lg') {
                margin-top: 24px;
            }

            @include mq('<=md') {
                margin-top: 0;
                margin-bottom: 32px;
            }
        }

        .usp-values--title-row {
            justify-content: center;
        }

        .usp-values--row {
            justify-content: center;

            @include mq('<=md') {
                justify-content: flex-start;
            }

            @include mq('<=sm') {
                justify-content: center;
            }
        }
    }

    // Facets
    &.usp-values__large {
        .usp-values--entry {
            @include grid--span((ratio: 4/12));
            @include grid--span((ratio: 6/12, mq: 'md'));
            @include grid--span((ratio: 8/12, mq: 'sm'));
            @include grid--span((ratio: 12/12, mq: 'xs'));
        }
    }

    // States
}
