.form-field-messages {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        margin-top: 6px;

        .form-field-messages--message {
            display: block;
            font-size: $fz--x-small;
            line-height: $lh--x-small;

            ~ .form-field-messages--message {
                margin-top: 3px;
            }

            &.form-field-messages--message__info {
                color: $c--signal-info;
            }

            &.form-field-messages--message__error {
                color: $c--signal-error;
                padding-left: 12px;
            }
        }
    }

    // Facets

    // States
}
