.industry-teaser {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0;
            overflow: hidden;

            @include mq('<=md') {
                padding: 30px 0;
            }
        }

        .industry-teaser--row {
            justify-content: center;
        }

        .industry-teaser--cta-col {
            margin-top: 32px;
        }

        .industry-teaser--col {
            @include grid--span((ratio: 8/12));
            @include grid--span((ratio: 12/12, mq:('>sm' '<=lg')));
            @include grid--span((ratio: 3/4, mq: 'sm'));
            @include grid--span((ratio: 4/4, mq: 'xs'));
        }

        .industry-teaser--pagination-wrapper {
            margin-bottom: 24px;
            display: flex;
            justify-content: center;

            @include mq('<=sm') {
                display: none;
            }
        }

        .industry-teaser--mobile-pagination-wrapper {
            display: none;
            margin-top: 24px;
            margin-bottom: -8px;

            @include mq('<=sm') {
                display: flex;
                justify-content: center;
            }
        }

        .industry-teaser--content-wrapper {
            position: relative;
            width: 100%;
        }

        .industry-teaser--video-wrapper {
            height: 100%;
        }

        .industry-teaser--mobile-pagination-bullet {
            display: inline-block;
            width: 5px;
            height: 5px;
            border-radius: 100%;
            background: $c--monochrome-light-gray;
            margin: 0 4px;
        }

        .industry-teaser--mobile-pagination-bullet.industry-teaser--mobile-pagination-bullet__active {
            background: $c--brand-secondary;
        }

        .industry-teaser--pagination-item {
            @include ff--base-bold;
            cursor: pointer;
            margin: 0 20px;
            padding-bottom: 4px;
            font-size: $fz--xx-medium;
            line-height: $lh--x-large;
            color: $c--spot-light-blue;
            border-bottom: solid 2px transparent;
            transition: border-bottom $trs--base, color $trs--base;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            &:not(.industry-teaser--pagination-item__active) {
                @include has-focus() {
                    color: $c--monochrome-white;
                }
            }
        }

        .industry-teaser--pagination-item.industry-teaser--pagination-item__active {
            color: $c--brand-secondary;
            border-bottom: solid 2px $c--brand-secondary;
        }

        .industry-teaser--swiper-container {
            overflow: visible;
        }

        .industry-teaser--slide {
            display: flex;
            flex-direction: column;
            opacity: 0.4;
            transition: opacity $trs--base;
            width: 772px;
            height: auto;
            //overflow: hidden;

            @include at-root(html ,'.ie10') {
                display: block;
            }

            @include at-root(html ,'.ie11') {
                display: block;
            }

            @include mq('<=md') {
                width: 663px;
            }

            @include mq('<=sm') {
                display: flex;
                width: 287px;
            }
        }

        .industry-teaser--slide-content {
            display: flex;
            height: 100%;
            //box-shadow: unset;
        }

        .industry-teaser--slide.industry-teaser--slide__active {
            opacity: 1;
        }

        .industry-teaser--description {
            display: block;
            max-width: 100%;
            flex-shrink: 1;

            @include type--copy-secondary;
            margin-bottom: 0;

            @include mq('<=md') {
                margin-bottom: 0;
            }
        }
    }

    // Facets

    // States
}
