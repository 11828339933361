.auto-slider {
    // Vars

    // Support

    // Module
    & {
        & {
            position: relative;
            width: 100%;

            &::before, &::after {
                content: '';
                position: absolute;
                top: 0;
                height: 100%;
                width: 1px;
            }

            @include mq('>md') {
                &::before {
                    border-left: $divider-border;
                }

                &::after {
                    border-right: $divider-border;
                }
            }

            &::before {
                left: 0;
            }

            &::after {
                right: 0;
            }
        }

        .auto-slider--slider-wrapper {
            transition-timing-function: linear;
        }
    }

    // Facets
    &.auto-slider__no-swiper {
        .auto-slider--slider-wrapper {
            display: flex;
            justify-content: center;
        }
    }

    // States
}
