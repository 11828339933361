.image-only {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0;

            @include mq('<=md') {
                padding: 30px 0;
            }
        }

        .image-only--row {
            justify-content: center;
        }

        .image-only--col {
            @include grid--span((ratio: 8/12));
            @include grid--span((ratio: 12/12, mq: '<=md'));

            &__full-width {
                @include grid--span((ratio: 12/12));
            }
        }

        .image-only--image {
            display: block;
            width: 100%;
        }
    }

    // Facets

    // States
}
