.testaccount-overlay-trigger.testaccount-overlay-trigger.testaccount-overlay-trigger.testaccount-overlay-trigger {
    // Vars

    // Support

    // Module
    & {
        & {
            display: inline;
            cursor: pointer;
            font-size: inherit;
        }
    }

    // Facets

    // States
}
