.usp-value-entry {
    // Vars

    // Support

    // Module
    & {
        & {
            display: flex;
            border: $divider-border;
            border-radius: $sz--border-radius-base;
            padding: 24px 0;
            flex-direction: column;
            height: 100%;
        }

        .usp-value-entry--title,
        .usp-value-entry--lead,
        .usp-value-entry--link {
            padding: 0 24px;
        }

        .usp-value-entry--title {
            @include type--h3;
            margin-bottom: 12px;

            @include mq('<=md') {
                margin-bottom: 12px;
            }
        }

        .usp-value-entry--lead.usp-value-entry--lead {
            flex-grow: 2;

            @include type--copy-secondary;
            margin-bottom: 0;

            @include mq('<=md') {
                margin-bottom: 0;
            }
        }

        .usp-value-entry--link-wrapper.usp-value-entry--link-wrapper {
            margin-top: 24px;
            padding-top: 24px;
            border-top: $divider-border;
            display: flex;
            justify-content: center;
        }

        .usp-value-entry--link {
            display: inline-block;
        }
    }

    // Facets

    // States
}
