.payment-solution-app {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0;

            @include mq('<=md') {
                padding: 30px 0;
            }
        }

        .payment-solution-app--content-row {
            align-items: center;
        }

        .payment-solution-app--col {
            @include grid--span((ratio: 6/12));
            @include grid--span((ratio: 12/12, mq: '<=sm'));
        }

        .payment-solution-app--text-col {
            @include mq('<=sm') {
                margin-top: 24px;
            }
        }

        .payment-solution-app--image {
            max-width: 100%;

            &.payment-solution-app--image__is-svg {
                width: 100%;
            }
        }

        .payment-solution-app--title {
            @include type--h2;
            margin-bottom: 12px;

            @include mq('<=md') {
                margin-bottom: 12px;
            }
        }

        .payment-solution-app--content {
            @include type--copy;
        }
    }

    // Facets
    &.payment-solution-app__text-inverted {
        color: $c--monochrome-white;

        .payment-solution-app--content {
            color: $c--monochrome-white;
        }

        .payment-solution-app--title {
            color: $c--monochrome-white;
        }
    }

    &.payment-solution-app__reversed {
        .payment-solution-app--text-col {
            @include mq('<=sm') {
                margin-bottom: 24px;
            }
        }

        .payment-solution-app--content-row {
            flex-direction: row-reverse;

            @include mq('<=sm') {
                flex-direction: column-reverse;
            }
        }
    }

    // States
}
