.navigation-entry {
    // Vars

    // Support

    // Module
    & {
        .navigation-entry--child {
            margin-left: 34px;
        }

        .navigation-entry--label {
            display: block;
            margin-bottom: 32px;
            font-size: $fz--btn-medium;
            line-height: 1;
        }

        .navigation-entry--label.navigation-entry--label__active {
            color: $c--brand-secondary;
        }
    }

    // Facets
    @import 'facets/navigation-entry__nav-preview';
    @import 'facets/navigation-entry__mobile-nav';

    // States
}
