.map {
    // Vars

    // Support

    // Module
    & {
        & {
            width: 100%;
            height: 460px;

            @include mq('<=md') {
                margin-top: 60px;
            }

            @include mq('<=sm') {
                margin-top: 32px;
                height: 260px;
            }
        }
    }

    // Facets

    // States
}
