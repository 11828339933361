&.content-card__same-height {
    & {
        height: 100%;
    }

    .content-card--wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;

        @include at-root(html, '.ie11') {
            height: auto;
        }
    }
}
