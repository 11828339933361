.payment-teaser {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0;

            @include mq('<=md') {
                padding: 30px 0;
            }
        }

        .payment-teaser--container.payment-teaser--container {
            @include mq('<=md') {
                max-width: unset;
                padding: 0;
            }
        }

        .payment-teaser--col {
            @include grid--span((ratio: 12/12));
        }

        .payment-teaser--title {
            margin-bottom: 24px;
        }

        .payment-teaser--slide {
            display: inline-block;
            width: 140px;
            margin: 0 48px;

            @include mq('<=md') {
                margin: 0 22px;
            }

            @include mq('<=xs') {
                margin: 0 16px;
            }
        }

        .payment-teaser--link-row {
            margin-top: 32px;
            justify-content: center;
        }
    }

    // Facets

    // States
}
