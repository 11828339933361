/// Primary
///
@mixin btn--double-button() {
    // Vars

    // Support

    // Module

    // Facets

    // States
    & {
        // Base
        @include btn--apply-states(()) {
            @include btn--double-button-base();
        }

        // Focus
        @include btn--apply-states(('disabled': false, 'focus': true)) {
            @include btn--double-button-focus();
        }

        // Active
        @include btn--apply-states(('disabled': false, 'focus': default, 'active': true)) {
            @include btn--double-button-active();
        }

        // Disabled
        @include btn--apply-states(('disabled': true, 'focus': default)) {
            @include btn--double-button-disabled();
        }
    }
}

/// Base
///
@mixin btn--double-button-base() {
    color: $c--monochrome-white;
}

/// Focus
///
@mixin btn--double-button-focus() {
    color: $c--monochrome-white;
}

/// Active
///
@mixin btn--double-button-active() {
    color: $c--monochrome-white;
}

/// Disabled
///
@mixin btn--double-button-disabled() {
    color: $c--monochrome-white;
    background-color: $c--signal-disabled;
}
