.reduced-nav {
    // Vars

    // Support

    // Module
    & {
        & {
            z-index: z-index('reduced-nav');
            position: fixed;
            top: 0;
            width: 100%;
            transition: top 200ms ease-in-out;
        }
    }

    // Facets
    &.reduced-nav__top {
        //
    }

    &.reduced-nav__not-top {
        //
    }

    &.reduced-nav__pinned {
        //
    }

    &.reduced-nav__unpinned {
        //
    }

    // States
}
