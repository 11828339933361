&.accordion-item__faq {
    & {
        border-top: $divider-border;

        &:last-child {
            border-bottom: $divider-border;
        }

        @include has-focus() {
            background-color: $c--monochrome-very-light-gray;
        }
    }

    .accordion-item--title.accordion-item--title {
        color: $c--brand-primary;
    }

    .accordion-item--content {
        color: $c--brand-blue-grey;
    }

    .accordion-item--indicator {
        color: $c--brand-secondary;
    }

    &.accordion-item__open {
        & {
            background-color: $c--monochrome-very-light-gray;
        }
    }
}
