.contact-info {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 32px;

            @include mq('<=md') {
                padding: 24px;
            }

            @include shadow-active;
        }

        .contact-info--title {
            @include type--copy-secondary;

            margin-bottom: 4px;
        }

        .contact-info--name {
            @include type--h3;
        }

        .contact-info--list-item {
            display: flex;
            margin-bottom: 12px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    // Facets

    // States
}
