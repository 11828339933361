.cms-main-section {
    // Vars

    // Support

    // Module
    & {
        padding-top: 40px;
        padding-bottom: 40px;

        @include mq('<=md') {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }

    // Facets
    & {
        &.cms-main-section__base {
            padding-top: 0;
            padding-bottom: 0;

            @include mq('<=md') {
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        &.cms-main-section__very-light-blue {
            background: $c--brand-very-light-blue;
        }

        &.cms-main-section__light-blue-grey {
            background: $c--brand-very-light-blue;
        }

        &.cms-main-section__light-grey {
            background: $c--monochrome-light-gray;
        }

        &.cms-main-section__light-gray {
            background: $c--monochrome-light-gray;
        }

        &.cms-main-section__blue {
            background: $c--brand-primary;
        }

        &.cms-main-section__white {
            background: $c--monochrome-white;
        }
    }

    // States
}
