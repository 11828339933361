&.content-teaser__payment-solution-intro {
    .content-teaser--title {

        margin-bottom: 16px;

        @include mq('<=md') {
            margin-bottom: 12px;
        }

        h2 {
            @include type--h2;
            margin-bottom: 0;

            @include mq('<=md') {
                @include type--h3;
                margin-bottom: 0;
            }
        }
    }

    .content-teaser--content-text {
        font-size: $fz--medium-larger;
        line-height: $lh--medium;

        @include mq('<=md') {
            font-size: $fz--medium;
            line-height: $lh--medium;
        }
    }

    .content-teaser--content-wrapper {
        padding: 80px 80px 80px 116px;

        @include mq('<=md') {
            padding: 40px 40px 40px 60px;
        }
    }

    .content-teaser--row.content-teaser--row {
        @include mq('<=sm') {
            display: block;
        }
    }

    .content-teaser--image-wrapper {
        max-width: 260px;
        max-height: 260px;
        margin: 0 auto;

        @include mq('<=md') {
            max-width: 208px;
            max-height: 208px;
        }

        img {
            position: relative;
            left: -24px;
        }

        @include at-root(html, '.ie11') {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }

    .content-teaser--left-pane {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $c--brand-very-light-blue;

        @include at-root(html, '.ie11') {
            position: relative;
            display: block;
        }
    }
}
