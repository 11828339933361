.contact-overlay.contact-overlay.contact-overlay {
    // Vars

    // Support

    // Module
    & {
        & {
            background-color: $c--monochrome-white;
            position: fixed;
            top: 80px;
            left: 50%;
            transform: translate3d(-50%, 0, 0);
            border-radius: $sz--border-radius-base;
            padding: 72px 0;
            width: 100%;
            max-width: 776px;
            overflow: auto;
            max-height: 80%;

            @include mq('md') {
                max-width: 664px;
            }

            @include mq('<=sm') {
                border-radius: 0;
                top: 0;
                max-width: 100%;
                height: 100%;
                overflow: auto;
                max-height: 100%;
            }
        }

        .contact-overlay--loader-wrapper {
            display: flex;
            justify-content: center;
        }

        .contact-overlay--row {
            justify-content: center;
        }

        .contact-overlay--col.contact-overlay--col {
            @include grid--span((ratio: 4/8));
            @include grid--span((ratio: 5/8, mq: 'md'));
            @include grid--span((ratio: 8/8, mq: '<=sm'));

            @include mq('>sm') {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    // Facets

    // States
}
