.list-item {
    // Vars

    // Support

    // Module
    & {
        & {
            transition: background-color $trs--base;
            border-bottom: $divider-border;

            &:first-child {
                border-top: $divider-border;
            }

            &::before {
                display: none;
            }

            &:nth-child(1n) {
                background-color: $c--monochrome-white;
            }

            &:nth-child(2n) {
                background-color: $c--monochrome-light-gray;
            }
        }

        .list-item--link {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 13px 16px 11px;
            color: $c--brand-blue-grey;
        }

        .list-item--icon {
            flex: 0 0 16px;
            color: $c--brand-secondary;
            width: 16px;
            height: 16px;
        }
    }

    // Facets
    &.list-item__link {
        @include has-focus() {
            background-color: $c--brand-secondary;
            color: $c--monochrome-paper-white;

            .list-item--link,
            .list-item--icon {
                color: $c--monochrome-paper-white;
            }
        }
    }

    // States
}
