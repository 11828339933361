.client-list {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0;

            @include mq('<=md') {
                padding: 30px 0;
            }
        }

        .client-list--container.client-list--container {
            overflow: visible;
        }

        .client-list--row.client-list--row {
            overflow: visible;
        }

        .client-list--title {
            margin-bottom: 20px;

            @include mq('<=md') {
                margin-bottom: 24px;
            }
        }

        .client-list--title-col {
            display: flex;
            flex-direction: column;

            @include grid--span((ratio: 9/12));
            @include grid--span((ratio: 12/12, mq: '<=md'));
        }

        .client-list--filter-col {
            display: flex;
            flex-direction: column;
            align-items: center;

            @include grid--span((ratio: 3/12));
            @include grid--span((ratio: 4/12, mq: '<=md'));
            @include grid--span((ratio: 12/12, mq: '<=sm'));
        }

        .client-list--filter {
            margin-bottom: 16px;
            cursor: pointer;
        }

        .client-list--list {
            justify-content: flex-start;
        }

        .client-list--item {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 140px;
            margin: 40px 0;

            @include grid--span((ratio: 2/12));
            @include grid--span((ratio: 3/12, mq: '<=md'));
            @include grid--span((ratio: 6/12, mq: '<=sm'));
        }

        .client-list--filter-option {
            color: $c--brand-blue-grey;
        }

        .client-list--filter-option__active {
            color: $c--brand-secondary;
        }
    }

    // Facets

    // States
}
