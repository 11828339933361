.navbar-item {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 11px 20px 12px;

            @include mq('<=lg') {
                padding-left: 12px;
                padding-right: 12px;
            }
        }

        .navbar-item--link {
            cursor: pointer;
            font-size: $fz--btn-medium;
            line-height: 1;
            display: flex;
            color: $c--brand-blue-grey;
            transition: color $trs--base;

            @include has-focus() {
                color: $c--brand-primary;
            }

            &.navbar-item--link__button {
                cursor: default;
            }
        }
    }

    // Facets
    &.navbar-item__active {
        .navbar-item--link {
            color: $c--brand-primary;
        }
    }

    &.navbar-item__extern {
        .navbar-item--link {
            color: $c--brand-secondary;

            @include has-focus() {
                color: $c--brand-dark-green;
            }
        }
    }

    // States
}
