.infographic-header {
    // Vars

    // Support

    // Module
    & {
        & {
            margin-top: -40px;
            padding: 75px 0;
            margin-bottom: -40px;

            @include mq('<=md') {
                margin-top: -30px;
                padding: 60px 0;
                margin-bottom: -30px;
            }
        }

        .infographic-header--container.infographic-header--container {
            @include mq('<=sm') {
                max-width: unset;
                padding: 0;
            }
        }

        .infographic-header--title {
            @include type--h1;
            margin-bottom: 12px;

            @include mq('<=md') {
                margin-bottom: 12px;
            }
        }

        .infographic-header--content-wrapper {
            overflow: visible;
            max-width: 100%;

            @include mq('<=sm') {
                width: 100%;
                margin: 0 auto;
                max-width: $screen-sm-min;
                padding: 0 24px;
            }
        }

        .infographic-header--single-link-wrapper {
            text-align: center;
        }

        .infographic-header--row {
            align-items: center;

            @include mq('<=md') {
                display: block;
            }
        }

        .infographic-header--content-col {
            z-index: z-index('infographic-header--content');
            position: relative;

            @include grid--span((ratio: 5/12));
            @include grid--span((ratio: 6/12, mq: '<=lg'));
            @include grid--span((ratio: 12/12, mq: '<=md'));
        }

        .infographic-header--visual-col {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;
            height: 100%;

            @include grid--span((ratio: 7/12));
            @include grid--span((ratio: 6/12, mq: '<=lg'));
            @include grid--span((ratio: 12/12, mq: '<=md'));

            @include mq('<=md') {
                display: block;
            }
        }

        .infographic-header--visual-wrapper {
            position: relative;
            width: 100%;
            padding-top: 100% / 3 * 2;
            overflow: hidden;
        }

        .infographic-header--visual {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;

            @supports (object-fit: cover) {
                object-fit: cover;
                object-position: center;
            }

            @include at-root(html, '.ie10') {
                transform: translate3d(0, 0, 0) scale(1.01);
            }

            @include at-root(html, '.ie11') {
                left: 50%;
                top: 50%;
                width: auto;
                transform: translateY(-50%) translateX(-50%) scale(1.01);
            }
        }

        .infographic-header--cta {
            display: inline-block;
            max-width: 407px;

            &.infographic-header--cta__single {
                @include mq('<=sm') {
                    margin: 0 auto;
                }
            }

            &.infographic-header--cta__mobile {
                display: block;
                margin: 0 auto;

                @include mq('<=sm') {
                    width: 100%;
                }

                @include mq('md') {
                    margin-top: 16px;
                }
            }

            &.infographic-header--cta__desktop.infographic-header--cta__desktop.infographic-header--cta__desktop.infographic-header--cta__desktop {
                @include mq('<=md') {
                    display: none;
                }
            }
        }

        .infographic-header--btn {
            border-radius: unset;
            white-space: nowrap;
        }

        .infographic-header--mobile-cta.infographic-header--mobile-cta.infographic-header--mobile-cta {
            @include mq('>md') {
                display: none;
            }
        }

        .infographic-header--mobile-cta-col {
            @include grid--span((ratio: 12/12));
        }
    }

    // Facets

    // States
}
