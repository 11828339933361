/// Field styles
///
@mixin form-field--secondary-select() {
    // Vars

    // Module
    & {
        .form-field--input.form-field--input.form-field--input {
            @include ff--base-semi-bold();

            border: solid 1px $c--brand-primary;
            border-radius: $sz--border-radius-base;
            padding: 13px 24px;
            color: $c--brand-primary;
            background-color: transparent;

            @include placeholder() {
                color: $c--brand-primary;
            }
        }

        .form-field--action {
            pointer-events: none;
            padding: 0;
        }
    }

    // States
    & {
        // Base
        @include form-field--apply-states(()) {
            @include form-field--secondary-select-base();
        }

        // Focus
        @include form-field--apply-states(('disabled': false, 'focus': true)) {
            @include form-field--secondary-select-focus();
        }

        // Disabled
        @include form-field--apply-states(('disabled': true, 'focus': default)) {
            @include form-field--secondary-select-disabled();
        }

        // Empty
        @include form-field--apply-states(('filled': false)) {
            @include form-field--secondary-select-empty();
        }

        // Filled
        @include form-field--apply-states(('filled': true)) {
            @include form-field--secondary-select-filled();
        }

        // Empty and disabled
        @include form-field--apply-states(('filled': false, 'disabled': true)) {
            @include form-field--secondary-select-empty-disabled();
        }
    }
}

/// Base
///
@mixin form-field--secondary-select-base() {
    .form-field--input {
        cursor: pointer;
    }

    .form-field--action.form-field--action__arrow {
        color: $c--brand-primary;
    }
}

/// Focus
///
@mixin form-field--secondary-select-focus() {
    .form-field--input {
        border: solid 1px $c--brand-primary;
    }

    .form-field--title-label {
        color: $c--brand-primary;
    }

    .form-field--action.form-field--action__arrow {
        color: $c--brand-primary;
    }
}

/// Disabled
///
@mixin form-field--secondary-select-disabled() {
    .form-field--action.form-field--action__arrow {
        color: $c--signal-disabled;
        cursor: default;
    }

    .form-field--input {
        color: $c--signal-disabled;
    }
}

/// Filled
///
@mixin form-field--secondary-select-filled() {
    .form-field--input {
        color: $c--brand-primary;
        border: solid 1px $c--brand-primary;
    }
}

/// Empty
///
@mixin form-field--secondary-select-empty() {
    .form-field--input {
        color: $c--brand-blue-grey;
    }
}

/// Empty and disabled
///
@mixin form-field--secondary-select-empty-disabled() {
    .form-field--input {
        color: transparent;
    }
}
