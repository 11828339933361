.navigation-preview {
    // Vars

    // Support

    // Module
    & {
        .navigation-preview--background {
            z-index: z-index('nav-preview-backdrop');
            position: fixed;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($c--brand-dark-blue, 0.6);
        }

        .navigation-preview--overlay {
            z-index: z-index('nav-preview');
            position: fixed;
            left: -360px;
            top: 0;
            display: block;
            width: 100%;
            max-width: 600px;
            padding-top: 30px;
            transition: transform $trs--base, opacity $trs--base, height $trs--base;

            @include mq('<=lg') {
                left: 0;
                max-width: 240px;
            }
        }

        .navigation-preview--wrapper {
            display: flex;
            flex-direction: row;
            background-color: $c--monochrome-white;
            width: 100%;
            height: 100%;
            border-radius: $sz--border-radius-base;
            overflow: hidden;

            @include shadow-active;
        }

        .navigation-preview--content {
            width: 100%;
            max-width: 360px;
            background-color: $c--monochrome-light-gray;

            @include mq('<=lg') {
                display: none;
            }
        }

        .navigation-preview--links {
            width: 100%;
            max-width: 240px;
        }

        .navigation-preview--link {
            border-bottom: solid 1px $c--brand-very-light-blue;
            color: $c--brand-secondary;
        }
    }

    // Facets
    &.navigation-preview__no-preview {
        & {
            left: 0;
            max-width: 240px;
        }

        .navigation-preview--content {
            display: none;
        }
    }

    &.navigation-preview__active {
        & {
            display: block;
        }
    }

    // States
}
