/// Field styles
///
@mixin form-field--primary-radio($sz--box) {
    // Vars
    $sz--radio-icon: 10px;

    // Module
    & {
        .form-field--input {
            ~ .form-field--label-wrap {
                .form-field--box {
                    &,
                    &::after,
                    &::before {
                        border-radius: $sz--box;
                    }
                }

                .form-field--box-icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: $sz--radio-icon;
                    height: $sz--radio-icon;
                    border-radius: $sz--box;
                    transform: translate(-50%, -50%);

                    background-color: currentColor;
                }
            }
        }
    }

    // States
}
