.address {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0;

            @include mq('<=md') {
                padding: 30px 0;
            }
        }

        .address--title.address--title {
            margin-bottom: 32px;

            @include mq('<=md') {
                font-size: $fz--xx-large;
                margin-bottom: 24px;
            }
        }

        .address--detail-row {
            justify-content: center;
        }

        .address--detail-col {
            @include grid--span((ratio: 4/12));
            @include grid--span((ratio: 5/12, mq: 'lg'));
            @include grid--span((ratio: 12/12, mq: '<=md'));

            @include mq('<=md') {
                margin: 0 auto;
                max-width: 380px;
            }
        }

        .address--detail-col {
            @include grid--span((ratio: 12/12));
        }

        .address--contact-detail-wrapper {
            display: flex;
            justify-content: center;
            width: 100%;
            margin: 0 auto 24px;
            max-width: 560px;

            @include mq('<=sm') {
                margin-bottom: 32px;
                flex-direction: column;
            }
        }

        .address--address {
            width: 100%;
            max-width: 240px;

            @include mq('<=sm') {
                margin-bottom: 24px;
                max-width: 100%;
            }
        }

        .address--contact-list {
            position: relative;
            left: -8px;
            margin-left: 16px;

            @include mq('<=md') {
                margin-left: 24px;
            }

            @include mq('<=sm') {
                margin-left: 0;
                left: 0;
            }
        }

        .address--contact-list-item {
            @include mq('<=sm') {
                &:last-child {
                    .address--contact-item {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .address--contact-item {
            display: flex;
            align-items: center;
            align-content: center;
            margin-bottom: 5px;

            font-size: $fz--btn-medium;
            line-height: 1;

            @include ff--base-semi-bold;

            //@include type--link-base;

            @include mq('<=sm') {
                margin-bottom: 16px;
            }
        }

        .address--contact-item-icon {
            margin-right: 8px;
        }

        .address--cta-wrapper {
            margin: 0 auto;
            width: 100%;
            max-width: 372px;
        }
    }

    // Facets

    // States
}
