.overlay-close-btn {
    // Vars

    // Support

    // Module
    & {
        & {
            pointer-events: auto;
            z-index: z-index('overlay-close-action');
            position: absolute;
            top: 24px;
            right: 24px;
        }

        .overlay-close-btn--icon {
            pointer-events: auto;
            color: $c--monochrome-black;
            cursor: pointer;
        }
    }

    // Facets
    &.overlay-close-btn__button {
        position: initial;
        top: unset;
        left: unset;
    }

    // States
}
