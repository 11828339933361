&.content-teaser__specialist {
    & {
        @include shadow-active;
    }

    .content-teaser--title {
        p {
            @include type--copy-secondary;
        }
    }

    .content-teaser--content {
        p {
            @include type--copy-secondary;
        }
    }

    .content-teaser--content-text {
        display: block;
        max-width: 100%;
    }

    .content-teaser--row {
        flex-direction: row-reverse;
    }

    .content-teaser--content-wrapper {
        padding: 40px;

        @include at-root(article, '.article') {
            @include mq('lg') {
                padding-right: 20px;
            }
        }
    }

    .content-teaser--right-pane {
        flex: 0 0 calc(100% - 332px);
        justify-content: flex-start;
    }

    .content-teaser--left-pane {
        position: relative;
        flex: 0 0 332px;

        img {
            position: absolute;
        }

        &::before {
            transform-origin: left bottom;
            transform: skew($skew) translateX(calc(-100% + 5px));
        }
    }

    &.content-teaser__active {
        .content-teaser--left-pane {
            &::before {
                transform: skew($skew) translateX(calc(-100% + 5px));
            }
        }
    }
}
