&.content-teaser__industry-detail-header {
    & {
        height: 100%;
        border-radius: 0;
    }

    .content-teaser--row {
        flex-direction: row-reverse;
        height: 100%;

        @include mq('<=sm') {
            flex-direction: column;
        }
    }

    .content-teaser--image-wrapper {
        position: relative;

        @include at-root(html, '.ie11') {
            video, img {
                height: auto;

                @include mq('<=md') {
                    height: 100%;
                    width: auto;
                }

                @include mq('<=sm') {
                    height: auto;
                    width: 100%;
                }
            }
        }
    }

    .content-teaser--right-pane.content-teaser--right-pane {
        z-index: z-index('content-teaser-right-pane');
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: 0;
        justify-content: flex-start;
        height: 100%;
        width: 100%;
        padding: 0;
        background: transparent;
        overflow: hidden;

        @include grid--span((ratio: 12/12));

        @include mq('<=sm') {
            position: relative;
            overflow: visible;
            top: 0;
            transform: unset;
        }
    }

    .content-teaser--content-wrapper {
        display: flex;
        padding: 0;
        width: 100%;
        margin: 0 auto;
        height: 100%;
    }

    .content-teaser--content {
        max-width: 473px;
    }

    .content-teaser--left-pane {
        @include grid--span((ratio: 4/10));
        @include grid--span((ratio: 10/10, mq: '<=sm'));

        @include mq('>sm') {
            z-index: z-index('content-teaser-left-pane');
        }

        @include mq('<=sm') {
            height: 400px;
            max-height: 400px;
        }

        &::before {
            right: 0;
            transform-origin: left bottom;
            transform: skew($skew) translateX(-100%);
        }
    }

    &.content-teaser__active {
        .content-teaser--left-pane {
            &::before {
                transform: skew($skew) translateX(-100%);
            }
        }
    }
}
