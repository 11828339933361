.meta-nav {
    // Vars

    // Support

    // Module
    & {
        & {
            z-index: z-index('meta-nav');
            position: relative;
            padding: 10px 0;
            background-color: $c--brand-primary;
            color: $c--monochrome-white;
            min-height: 40px;

            @include mq('<=md') {
                display: none;
            }
        }

        .meta-nav--container.meta-nav--container {
            @include mq('<=lg') {
                max-width: unset;
            }
        }

        .meta-nav--content {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            @include grid--span((ratio: 12/12));
        }

        .meta-nav--item {
            border-right: 1px solid $c--brand-blue-grey;
            font-size: $fz--x-medium;
            line-height: 1;
            padding: 2px 0;

            &:last-child {
                border-right: none;
            }

            &.meta-nav--item__mobile {
                display: none;
            }

            &.meta-nav--item__desktop {
                @include mq('<=sm') {
                    display: none;
                }
            }
        }

        .meta-nav--link {
            position: relative;
            top: 1px;
            color: $c--brand-light-blue-gray;
            padding: 0 12px;
            cursor: pointer;

            @include has-focus() {
                color: $c--monochrome-white;
            }
        }

        .meta-nav--lang-switch {
            position: relative;
            top: 1px;
        }

        .meta-nav--item {
            // It's the only way... sorry - @fabs
            .testaccount-overlay-trigger.testaccount-overlay-trigger.testaccount-overlay-trigger.link {
                position: relative;
                top: 1px;
                color: $c--brand-light-blue-gray;
                padding: 0 12px;
                cursor: pointer;

                @include has-focus() {
                    color: $c--monochrome-white;
                    background-color: transparent;
                    box-shadow: none;
                }
            }
        }
    }

    // Facets
    &.meta-nav__mobile {
        & {
            border-top: solid 1px $c--brand-blue-grey;
            background-color: $c--brand-dark-blue;

            @include mq('<=md') {
                display: block;
            }
        }

        .meta-nav--container.meta-nav--container {
            padding: 0 40px;

            @include mq('<=sm') {
                padding: 0 24px;
            }
        }

        .meta-nav--content {
            flex-direction: column;
        }

        .meta-nav--link {
            padding: 0;
        }

        .meta-nav--item {
            border-right: none;
            margin: 12px 0;

            &.meta-nav--item__desktop {
                display: none;
            }

            &.meta-nav--item__mobile {
                display: block;

                &:first-child {
                    margin-top: 10px;
                }
            }
        }

        .meta-nav--lang-switch-item {
            display: inline-block;
            border-top: none;
        }
    }

    // States
}
