.lang-switch {
    // Vars

    // Support

    // Module
    & {
        .lang-switch--backdrop {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .lang-switch--current-lang {
            text-transform: uppercase;
            padding-left: 12px;
            padding-right: 4px;
            font-size: $fz--x-medium;
            line-height: 1;
            color: $c--brand-light-blue-gray;
        }

        .lang-switch--toggle {
            position: relative;
            display: inline-flex;
            text-transform: uppercase;
            padding-left: 12px;
            padding-right: 4px;
            font-size: $fz--x-medium;
            line-height: 1;
            color: $c--brand-light-blue-gray;
        }

        .lang-switch--chevron {
            margin-left: 6px;
            transition: transform $trs--base;

            &.lang-switch--chevron__active {
                transform: rotateZ(180deg);
            }
        }

        .lang-switch--overlay {
            z-index: z-index('language-switch');
            position: absolute;
            top: 40px;

            @include shadow-active;
        }

        .lang-switch--item {
            text-transform: uppercase;
            cursor: pointer;
            display: block;
            padding: 18px 20px 12px;
            color: $c--brand-blue-grey;
            border-top: $divider-border;
            font-size: $fz--btn-medium;
            background: $c--monochrome-white;

            &:first-child {
                border-top: none;
            }

            @include has-focus() {
                background: $c--brand-very-light-blue;
            }

            &.lang-switch--inline-item__active {
                background: $c--monochrome-light-gray;
            }
        }

        .lang-switch--inline-item {
            text-transform: uppercase;
            color: $c--brand-light-blue-gray;
            margin: 0 12px;
            cursor: pointer;
            opacity: 0.5;

            @include has-focus() {
                color: $c--monochrome-white;
            }

            &:first-child {
                margin-left: 0;
            }

            &.lang-switch--inline-item__active {
                opacity: 1;
            }
        }
    }

    // Facets

    // States
}
