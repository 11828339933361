/// Font weights used throughout the project
///

$fw--normal: 500;
$fw--semi-bold: 600;

/// The project's font families
///
// TODO: Define font-families
$ff--base: 'Volte';

$ff--base-regular-filename: '36CE02_1_0';
$ff--base-semi-bold-filename: '36CE02_0_0';

/// Font stack definitions
/// @link http://www.cssfontstack.com/
///
// TODO: Figure out font-stack fallback definitions
// stylelint-disable value-keyword-case
$ff--base-stack: $ff--base, 'Helvetica Neue', Helvetica, Arial;
// stylelint-enable value-keyword-case
