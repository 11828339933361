.solution-overview-group {
    // Vars

    // Support

    // Module
    & {
        .solution-overview-group--item, .solution-overview-group--link {
            color: $c--brand-blue-grey;
            font-size: $fz--copy;
            line-height: $lh--copy;
            margin: 8px 0;
        }

        .solution-overview-group--item {
            &:first-child {
                @include ff--base-semi-bold;
                margin-top: 0;
                margin-bottom: 0;
                color: $c--brand-primary;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .solution-overview-group--link {
            color: $c--brand-blue-grey;

            @include has-focus() {
                color: $c--brand-primary;
            }
        }
    }

    // Facets

    // States
}
