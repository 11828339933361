.article-preview {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 24px 0;
        }

        .article-preview--row {
            display: flex;
            box-shadow: none;
            border-radius: $sz--border-radius-base;
            transition: box-shadow $trs--base;

            @include mq('<=sm') {
                flex-direction: column-reverse;
            }
        }

        .article-preview--content-col {
            display: flex;
            justify-content: center;
            flex-direction: row;

            @include mq('<=sm') {
                flex-direction: column-reverse;
            }
        }

        .article-preview--content {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            width: calc(100% - 303px);
            margin-right: 24px;

            @include mq('<=md') {
                width: 100%;
            }
        }

        .article-preview--visual {
            max-width: 303px;
            width: 100%;

            @include mq('<=md') {
                max-width: 232px;
            }

            @include mq('<=sm') {
                max-width: 100%;
                margin-bottom: 12px;
            }
        }

        .article-preview--title {
            @include type--h3;
            color: $c--brand-primary;
            margin-bottom: 12px;

            @include mq('<=md') {
                margin-bottom: 12px;
            }
        }

        .article-preview--date {
            @include type--copy-secondary;
            color: $c--brand-blue-grey;
            flex: auto;

            @include mq('<=sm') {
                margin-bottom: 0;
            }
        }

        .article-preview--description {
            @include type--copy-tertiary;
            color: $c--brand-blue-grey;
            margin-bottom: 12px;

            @include mq('<=sm') {
                margin-bottom: 12px;
            }
        }
    }

    // Facets

    // States
}
