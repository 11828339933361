.testimonial-list {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0;

            @include mq('<=md') {
                padding: 30px 0;
            }
        }

        .testimonial-list--title {
            @include type--h2;

            text-align: center;
            margin-bottom: 32px;

            @include mq('<=md') {
                margin-bottom: 24px;
            }
        }

        .testimonial-list--wrapper-row {
            justify-content: center;
        }

        .testimonial-list--row {
            justify-content: flex-start;
        }

        .testimonial-list--title-col {
            @include grid--span((ratio: 12/12));
        }

        .testimonial-list--col {
            overflow: hidden;

            @include grid--span((ratio: 6/12));
            @include grid--span((ratio: 12/12, mq: '<=md'));

            @include mq('>md') {
                &:nth-child(2) {
                    margin-top: 60px;
                }
            }

            @include mq('<=md') {
                margin-top: 32px;

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        .testimonial-list--wrapper-col {
            width: 100%;
            max-width: 100%;

            @include grid--span((ratio: 10/12));
            @include grid--span((ratio: 8/12, mq: '<=md'));
            @include grid--span((ratio: 4/4, mq: '<=sm'));
        }

        .testimonial-list--item {
            width: 100%;

            @include mq('>md') {
                padding-top: 60px;
            }

            @include mq('<=md') {
                padding-top: 32px;
            }

            &:first-child {
                padding-top: 0;
            }
        }

        .testimonial-list--list {
            justify-content: center;
        }
    }

    // Facets
    &.testimonial-list__teaser {
        .testimonial-list--col {
            @include mq('>md') {
                &:nth-child(2) {
                    margin-top: 0;
                }
            }
        }
    }

    &.testimonial-list__teaser.testimonial-list__small {
        .testimonial-list--col {
            @include grid--span((ratio: 4/12));
            @include grid--span((ratio: 12/12, mq: '<=md'));
        }
    }

    &.testimonial-list__teaser.testimonial-list__medium {
        .testimonial-list--col {
            @include grid--span((ratio: 5/10));
            @include grid--span((ratio: 12/12, mq: '<=md'));
        }
    }

    &.testimonial-list__teaser.testimonial-list__large {
        .testimonial-list--col {
            @include grid--span((ratio: 6/12));
            @include grid--span((ratio: 12/12, mq: '<=md'));
        }
    }

    // States
}
