.cookie-consent {
    // Vars

    // Support

    // Module
    & {
        & {
            margin-bottom: 24px;
        }

        .cookie-consent--title {
            margin-bottom: 8px;
        }

        .cookie-consent--input-col {
            @include grid--span((ratio: 4/12));
            @include grid--span((ratio: 12/12, mq: '<=sm'));
        }

        .cookie-consent--text-col {
            @include grid--span((ratio: 8/12));
            @include grid--span((ratio: 12/12, mq: '<=sm'));

            @include mq('<=sm') {
                margin-top: 8px;
            }
        }
    }

    // Facets

    // States
}
