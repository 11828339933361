&.content-card__circle-header {
    & {
        @include content-card-image(100px, 24px);
    }

    .content-card--header-image {
        border-radius: 100%;

        img, video {
            width: 100%;
            height: 100%;
        }
    }
}
