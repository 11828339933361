.next-best-action.next-best-action.next-best-action.next-best-action {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0;
            color: $c--monochrome-white;
            text-align: center;

            @include mq('<=md') {
                padding: 30px 0;
            }
        }

        .next-best-action--row {
            justify-content: center;
        }

        .next-best-action--col {
            @include grid--span((ratio: 8/12));
            @include grid--span((ratio: 6/12, mq: '<=md'));
            @include grid--span((ratio: 4/4, mq: '<=sm'));

            .contact-overlay-trigger {
                text-decoration: none;
            }

            .testaccount-overlay-trigger {
                text-decoration: none;
            }
        }

        .next-best-action--content {
            @include type--h1;
            margin-bottom: 0;
            color: $c--monochrome-white;

            a {
                @include link--base;
            }
        }
    }

    // Facets

    // States
}
