&.accordion-item__mobile-nav {
    & {
        @include grid--root;
        transition: background-color $trs--base;

        @each $value in $grid--base-container-sizes {
            @include grid--constrain($value);
        }

        @each $value in $grid--base-spacing {
            @include grid--space($value);
        }
    }

    .accordion-item--container {
        padding: 0 24px 0 40px;

        @include mq('<=sm') {
            max-width: unset;
            padding: 0 20px 0 24px;
        }
    }

    .accordion-item--title.accordion-item--title {
        font-size: $fz--btn-medium;
        line-height: 1;
        color: $c--monochrome-white;
    }

    .accordion-item--indicator {
        color: $c--monochrome-white;
    }

    .accordion-item--title-wrapper {
        padding: 8px 0;
    }

    .accordion-item--content {
        padding: 0;
        transition: height 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    &.accordion-item__active {
        .accordion-item--title {
            color: $c--brand-secondary;
        }
    }

    &.accordion-item__open {
        background-color: $c--brand-dark-blue;
        margin-bottom: 8px;
    }
}
