.clients-teaser {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0;

            @include mq('<=md') {
                padding: 30px 0;
            }
        }

        .clients-teaser--container.clients-teaser--container {
            @include mq('<=md') {
                max-width: 100%;
                padding: 0;
            }
        }

        .clients-teaser--slide {
            display: inline-block;
            width: 140px;
            margin: 0 48px;

            @include mq('<=md') {
                margin: 0 30px;
            }

            @include mq('<=sm') {
                margin: 0 20px;
            }
        }

        .clients-teaser--content {
            display: flex;
            justify-content: center;
        }

        .clients-teaser--col {
            @include grid--span((ratio: 12/12));
        }

        .clients-teaser--title {
            margin-bottom: 32px;

            @include mq('<=md') {
                margin-bottom: 24px;
            }
        }
    }

    // Facets

    // States
}
