.specialist {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0;

            @include mq('<=sm') {
                padding: 30px 0;
            }
        }

        .specialist--container.specialist--container {
            overflow: visible;
        }

        .specialist--row {
            justify-content: center;
            flex-flow: row nowrap;
        }

        .specialist--col {
            @include grid--span((ratio: 8/12));
            @include grid--span((ratio: 10/12, mq: 'lg'));
            @include grid--span((ratio: 12/12, mq: 'md'));
            @include grid--span((ratio: 3/4, mq: 'sm'));
            @include grid--span((ratio: 4/4, mq: '<=xs'));

            @include at-root(article, '.article') {
                @include mq('lg') {
                    @include grid--span((ratio: 8/12, mq: 'lg'));
                }
            }
        }

        .specialist--content {
            @include type--copy-secondary;
            display: block;
            max-width: 100%;
            width: 100%;
            margin-bottom: 0;

            @include mq('<=md') {
                margin-bottom: 0;
            }
        }

        .specialist--statement.specialist--statement.specialist--statement {
            @include type--copy-secondary;
            display: block;
            max-width: 100%;
            width: 100%;
            margin-bottom: 0;

            @include mq('<=md') {
                margin-bottom: 0;
            }
        }

        .specialist--title.specialist--title {
            margin-bottom: 4px;
        }

        .specialist--role {
            @include type--copy-secondary;
            margin-bottom: 24px;

            @include mq('<=md') {
                margin-bottom: 24px;
            }
        }

        .specialist--links {
            @include mq('<=sm') {
                border-top: $divider-border;
            }
        }

        .specialist--link-wrapper.specialist--link-wrapper.specialist--link-wrapper {
            display: flex;
            margin-bottom: 16px;

            @include mq('<=sm') {
                display: flex;
                justify-content: center;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .specialist--link.specialist--link.specialist--link {
            display: inline-flex;
            align-items: center;
            margin: 8px 0;
            font-size: $fz--btn-medium;
            text-decoration: none;

            //@include type--link-base;

            @include ff--base-bold();

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .specialist--link-icon {
            display: inline;
            margin-right: 8px;
        }
    }

    // Facets

    // States
}
