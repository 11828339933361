.gallery {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0;

            @include mq('<=md') {
                padding: 30px 0;
            }
        }

        .gallery--swiper-container {
            height: 100%;
            max-width: 776px;
            overflow: visible;
            margin: 0 auto;

            @include mq('md') {
                max-width: 664px;
            }

            @include mq('<=sm') {
                max-width: 100%;
            }
        }

        .gallery--swiper-wrapper {
            height: auto;
        }

        .gallery--slide {
            opacity: 0;
            transition: opacity $trs--base;

            &.gallery--slide__active {
                opacity: 1;
            }
        }

        .gallery--slide-content {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            height: 100%;
            width: 100%;
            max-height: 437px;
            overflow: hidden;

            @include mq('<=md') {
                max-height: 375px;
            }

            @include mq('<=xs') {
                max-height: 184px;
            }
        }

        .gallery--image {
            width: 100%;
            height: 100%;
        }

        .gallery--wrapper-row {
            position: relative;
            justify-content: center;
        }

        .gallery--wrapper.gallery--wrapper {
            @include grid--span((ratio: 12/12));

            @include mq('<=sm') {
                max-width: 100%;
            }
        }

        .gallery--btn {
            z-index: z-index('gallery--btn');
            cursor: pointer;
            position: absolute;
            top: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translate3d(0, -50%, 0);
            width: 40px;
            height: 40px;
            border-radius: 100%;
            background-color: $c--monochrome-light-gray;
            transition: background-color $trs--base, color $trs--base, opacity $trs--base;

            &.gallery--btn__disabled {
                opacity: 0.1;
                cursor: unset;
            }

            &:not(.gallery--btn__disabled) {
                @include has-focus() {
                    background-color: $c--brand-primary;
                    color: $c--monochrome-white;
                }
            }
        }

        .gallery--btn-prev {
            left: -72px;

            @include mq('<=md') {
                transform: translate3d(-100%, -50%, 0);
                left: -6px;
            }

            @include mq('<=sm') {
                transform: translate3d(-50%, -50%, 0);
                left: -2px;
            }
        }

        .gallery--btn-next {
            right: -72px;

            @include mq('<=md') {
                transform: translate3d(100%, -50%, 0);
                right: -6px;
            }

            @include mq('<=sm') {
                transform: translate3d(50%, -50%, 0);
                right: -2px;
            }
        }
    }

    // Facets
    &.gallery__light-grey {
        .gallery--btn {
            background-color: $c--monochrome-white;
        }
    }

    &.gallery__full-width {
        .gallery--swiper-container {
            max-width: 100%;
        }

        .gallery--slide-content {
            max-height: initial;
        }
    }

    &.gallery__uncropped {
        .gallery--slide-content {
            max-height: unset;

            @include mq('<=md') {
                max-height: unset;
            }

            @include mq('<=xs') {
                max-height: unset;
            }
        }
    }

    // States
}
