.payment-solution-intro {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0;

            @include mq('<=md') {
                padding: 30px 0;
            }
        }

        .payment-solution-intro--content-teaser-row {
            justify-content: center;
            margin: 40px 0;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            @include mq('<=sm') {
                margin-top: 0;
                margin-bottom: 24px;
            }
        }

        .payment-solution-intro--content-card-row {
            justify-content: center;
        }

        .payment-solution-intro--content-teaser-col {
            @include grid--span((ratio: 12/12));
            @include grid--span((ratio: 8/12, mq: '<=sm'));
            @include grid--span((ratio: 4/4, mq: '<=xs'));
        }

        .payment-solution-intro--content-card-col {
            @include grid--span((ratio: 4/12));
            @include grid--span((ratio: 6/12, mq: '<=md'));
            @include grid--span((ratio: 8/12, mq: '<=sm'));
            @include grid--span((ratio: 4/4, mq: '<=xs'));

            @include mq('<=md') {
                margin-bottom: 24px;
            }
        }
    }

    // Facets

    // States
}
