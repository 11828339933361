.testimonial {
    // Vars

    // Support

    // Module
    & {
        & {
            text-align: center;
        }

        .testimonial--statement {
            font-size: $fz--medium;
            line-height: $lh--medium;
            margin-bottom: 12px;
        }

        .testimonial--description {
            font-size: $fz--small;
            line-height: $lh--small;
            color: $c--brand-primary;
        }

        .testimonial--name {
            font-size: $fz--small;
            line-height: $lh--small;
            font-weight: 600;
            text-align: center;
            margin-bottom: 4px;
            color: $c--brand-primary;
        }
    }

    // Facets

    // States
}
