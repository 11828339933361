.faq {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0;

            @include mq('<=md') {
                padding: 30px 0;
            }

            & + .faq {
                padding-bottom: 20px;

                @include mq('<=md') {
                    padding: 30px 0;
                }
            }
        }

        .faq--title-row {
            justify-content: center;
            margin-bottom: 32px;

            @include mq('<=md') {
                margin-bottom: 24px;
            }
        }

        .faq--content-row {
            justify-content: center;
        }

        .faq--title-col {
            @include grid--span((ratio: 8/12));
            @include grid--span((ratio: 12/12, mq: '<=md'));
        }

        .faq--content-col {
            @include grid--span((ratio: 8/12));
            @include grid--span((ratio: 12/12, mq: '<=md'));
        }

        .faq--title.faq--title {
            margin-bottom: 0;
        }
    }

    // Facets

    // States
}
