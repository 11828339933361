.content-card {
    // Vars
    $header-image-center-offset: 8px;

    // Support
    @mixin content-card-image($header-image-size, $header-image-margin) {
        & {
            padding-top: $header-image-size / 2 + $header-image-center-offset;
        }

        .content-card--header-image {
            width: $header-image-size;
            height: $header-image-size;
        }

        .content-card--content-wrapper {
            padding: $header-image-size/2 + ($header-image-margin - $header-image-center-offset) 24px 24px;
        }
    }

    // Module
    & {
        & {
            @include content-card-image(128px, 16px);
        }

        .content-card--wrapper {
            position: relative;
            background-color: $c--monochrome-white;
            border-radius: $sz--border-radius-base;
            box-shadow: none;
            max-width: 100%;
            max-height: 100%;

            @include at-root(html, '.ie11') {
                display: block;
            }
        }

        .content-card--header-image {
            position: absolute;
            top: -$header-image-center-offset;
            left: 50%;
            overflow: hidden;
            transform: translate3d(-50%, -50%, 0);

            img, video {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                transform: translate3d(-50%, -50%, 0);
            }
        }

        .content-card--title {
            @include type--h3;

            margin-bottom: 12px;

            @include mq('<=md') {
                margin-bottom: 12px;
            }
        }

        .content-card--content-wrapper {
            flex-grow: 1;

            @include at-root(html, '.ie10') {
                -ms-flex-positive: 0; // stylelint-disable-line property-no-vendor-prefix
            }
        }

        .content-card--content {
            @include type--copy-secondary;
            margin-bottom: 0;

            @include mq('<=md') {
                margin-bottom: 0;
            }
        }

        .content-card--links {
            display: flex;
            justify-content: center;
            border-top: $divider-border;
            padding: 24px 0;

            &:empty {
                display: none;
            }
        }
    }

    // Facets
    @import './facets/content-card__outlined';
    @import './facets/content-card__specialist';
    @import './facets/content-card__circle-header';
    @import './facets/content-card__same-height';
    @import './facets/content-card__nba';

    // States
}
