.statement {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0 20px;

            @include mq('<=md') {
                padding: 30px 0 10px;
            }
        }

        .statement--row {
            justify-content: center;
        }

        .statement--blockquote.statement--blockquote {
            display: block;
            max-width: 100%;
            text-align: center;

            @include type--h1;
            margin-bottom: 16px;

            @include mq('<=md') {
                margin-bottom: 16px;
            }

            @include mq('<=sm') {
                font-size: $fz--xx-medium;
            }

            a {
                @include link--base;
                border-bottom: none;
                font-size: 4.4rem;
                line-height: 1.14;
            }
        }

        .statement--cite {
            @include type_-copy-tertiary;
            margin-bottom: 0;
        }

        .statement--col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @include grid--span((ratio: 8/12));
            @include grid--span((ratio: 10/12, mq: '<=md'));
            @include grid--span((ratio: 12/12, mq: '<=sm'));
        }
    }

    // Facets

    // States
}
