.link-list {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0;
            background-color: $c--monochrome-light-gray;

            @include mq('<=md') {
                padding: 30px 0;
            }
        }

        .link-list--title.link-list--title.link-list--title {
            margin-bottom: 32px;
        }

        .link-list--content-col {
            @include grid--span((ratio: 12/12));
        }

        .link-list--list-item {
            display: flex;
            justify-content: space-between;
            border-top: $divider-border;
            padding: 13px 6px 13px 0;
            font-size: $fz--btn-medium;

            &:last-child {
                border-bottom: $divider-border;
            }
        }

        .link-list--label {
            display: block;
        }

        .link-list--link-label {
            @include mq('<=sm') {
                display: none;
            }
        }

        .link-list--link {
            display: block;
        }
    }

    // Facets
    &.link-list__additional-padding {
        & {
            margin-bottom: 40px;
            padding: 80px 0;
            background-color: $c--monochrome-light-gray;

            @include mq('<=md') {
                margin-bottom: 30px;
                padding: 30px 0;
            }
        }
    }

    // States
}
