.industry-detail-header {
    // Vars

    // Support

    // Module
    & {
        & {
            position: relative;
            height: 440px;
            max-height: 440px;
            overflow: hidden;

            @include mq('<=md') {
                height: initial;
                max-height: initial;
                overflow: visible;
            }

            @include at-root(html, '.ie11') {
                overflow: visible;
            }
        }

        .industry-detail-header--row.industry-detail-header--row {
            justify-content: flex-end;
            align-items: center;
            height: 100%;

            @include mq('<=md') {
                display: block;
            }

            @include at-root(html, '.ie11') {
                position: relative;
                display: block;
                overflow: hidden;

                @include mq('<=md') {
                    display: block;
                }
            }
        }

        .industry-detail-header--col {
            display: flex;

            @include at-root(html, '.ie11') {
                @include mq('<=md') {
                    display: block;
                }
            }
        }

        .industry-detail-header--right-pane.industry-detail-header--right-pane {
            z-index: z-index('content-teaser-right-pane');
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            left: 0;
            justify-content: flex-start;
            height: 100%;
            width: 100%;

            background: transparent;
            //overflow: hidden;

            @include grid--span((ratio: 12/12));

            @include mq('<=md') {
                position: relative;
                top: initial;
                transform: initial;
            }
        }

        .industry-detail-header--image-hider-col {
            @include grid--span((ratio: 12/12));
        }

        .industry-detail-header--left-pane.industry-detail-header--left-pane {
            @include grid--span((ratio: 5/12));
            @include grid--span((ratio: 12/12, mq: '<=md'));

            position: relative;
            display: block;
            overflow: hidden;
            padding-left: 0;
            height: 100%;

            @include at-root(html, '.ie11') {
                position: absolute;
                right: 0;

                @include mq('<=md') {
                    position: relative;
                    right: unset;
                }
            }

            &::before {
                content: '';
                pointer-events: none;
                z-index: z-index('content-teaser-triangle');
                position: absolute;
                top: 0;
                width: 100%;
                height: calc(100% + 2px);
                transition: transform $trs--base;
                background-color: $c--monochrome-white;
                transform-origin: left bottom;
                transform: skew($skew) translateX(-100%);

                @include mq('md') {
                    left: calc((100% - #{ $screen-md-min } + 40px) / 2 + 32px);
                }

                @include mq('sm') {
                    left: calc((100% - #{ $screen-sm-min }) / 2 + 24px);
                }

                @include mq('xs') {
                    left: 36px;
                }
            }
        }

        .industry-detail-header--image-wrapper {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            max-width: 100%;
            overflow: hidden;

            @include mq('<=md') {
                max-height: 400px;
            }

            img, video {
                display: block;
                height: 100%;
                width: 100%;
                max-width: 100%;

                @supports (object-fit: cover) {
                    object-fit: cover;
                    object-position: 50% 50%;
                }
            }

            @include at-root(html, '.ie11') {
                video, img {
                    height: auto;

                    @include mq('<=md') {
                        height: 100%;
                        width: auto;
                    }
                }
            }
        }

        .industry-detail-header--title {
            @include type--h1;
            margin-bottom: 0;

            @include mq('>md') {
                margin-bottom: 12px;
            }

            @include mq('<=md') {
                margin-bottom: 0;
            }
        }

        .industry-detail-header--desktop-title-wrapper {
            @include mq('<=md') {
                display: none;
            }

            @include at-root(html, '.ie11') {
                margin-top: 80px;
            }
        }

        .industry-detail-header--title-text {
            @include at-root(html, '.ie11') {
                @include mq('<=md') {
                    padding: 0;
                }
            }

            @include mq('<=md') {
                background-color: $c--monochrome-white;
                padding: 3px;
                //box-shadow: 0 0 0 2px $c--monochrome-white;
                line-height: 1.2;
            }
        }

        .industry-detail-header--content {
            @include mq('<=md') {
                margin-top: 32px;
            }
        }

        .industry-detail-header--content-btn {
            display: inline-block;
        }

        .industry-detail-header--content-col.industry-detail-header--content-col {
            @include grid--span((ratio: 5/12));
            @include grid--span((ratio: 7/12, mq: 'lg'));
            @include grid--span((ratio: 12/12, mq: '<=md'));
        }

        .industry-detail-header--mobile-title-wrapper {
            z-index: 11;
            position: absolute;
            top: 60px;

            @include mq('md') {
                left: calc((100% - #{ $screen-md-min } + 40px) / 2 + 32px);
            }

            @include mq('sm') {
                left: calc((100% - #{ $screen-sm-min }) / 2 + 24px);
            }

            @include mq('xs') {
                left: 36px;
            }

            @include mq('>md') {
                display: none;
            }
        }
    }

    // Facets

    // States
}
