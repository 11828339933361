.payment-method {
    // Vars

    // Support

    // Facets
    &.payment-method__clickable {
        & {
            display: flex;
            box-shadow: unset;
            cursor: pointer;
            transition: box-shadow $trs--base;
            border-radius: $sz--border-radius-base;
            justify-content: center;

            @include has-focus() {
                @include shadow-active;
            }
        }
    }

    // States
}
