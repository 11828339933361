.contact-price-form {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0;

            @include mq('<=md') {
                padding: 30px 0;
            }
        }

        .contact-price-form--title {
            @include type--h3;
            margin-bottom: 16px;
        }

        .contact-price-form--lead {
            @include type--copy;
            @include ff--base-semi-bold;
            color: $c--brand-blue-grey;
            margin-bottom: 16px;
            padding-top: 24px;
        }

        .contact-price-form--label {
            font-size: $fz--x-small;
            margin-bottom: 14px;
        }

        .contact-price-form--privacy-label {
            font-size: $fz--small;

            > a {
                @include link--base;
                @include link--underlined;
            }
        }

        .contact-price-form--field {
            padding: 16px 8px;
        }

        .contact-price-form--row {
            justify-content: center;
        }

        .contact-price-form--col {
            @include grid--span((ratio: 4/12));
            @include grid--span((ratio: 8/12, mq: '<=md'));
            @include grid--span((ratio: 12/12, mq: '<=xs'));
        }

        .contact-price-form--buttons {
            margin-top: 30px;
        }
    }

    // Facets
    .contact-price-form--field {
        &.contact-price-form--field__checkboxinput {
            padding: 4px 8px;
        }

        &.contact-price-form--field__checkboxselectmultiple {
            padding: 16px 8px 52px;
        }

        &.contact-price-form--field__radioselect {
            padding: 20px 8px 16px;
        }
    }

    &.contact-price-form__overlay {
        & {
            padding: 0;

            @include mq('<=md') {
                padding: 0;
            }
        }

        .contact-price-form--title {
            @include mq('<=md') {
                font-size: $fz--xx-large;
            }
        }

        .contact-price-form--lead {
            margin-bottom: 12px;
        }

        .grid--container.grid--container {
            max-width: 100%;
        }

        .contact-price-form--col.contact-price-form--col {
            @include grid--span((ratio: 12/12));
            padding: 0 16px;
        }

        .contact-price-form--buttons {
            margin-top: 16px;
        }
    }

    // States
}
