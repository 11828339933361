&.content-card__specialist {
    & {
        @include content-card-image(100px, 24px);
    }

    .content-card--content-wrapper {
        padding-top: 74px;
    }

    .content-card--header-image {
        border-radius: 100%;
        top: 0;
    }

    .content-card--wrapper {
        @include shadow-active;
    }

    .content-card--title {
        margin-bottom: 4px;
    }
}
