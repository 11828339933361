.hover-reveal {
    // Vars

    // Support

    // Module
    & {
        & {
            position: relative;
        }

        .hover-reveal--label {
            cursor: pointer;
            display: flex;
            align-items: center;
        }

        .hover-reveal--content {
            z-index: z-index('hover-reveal--content');
            position: absolute;
            top: 0;
            display: none;
            background: $c--monochrome-white;
            opacity: 0;
            transition: display 0ms, opacity $trs--base;

            @include shadow-active;
        }

        .hover-reveal--wrapper.hover-reveal--wrapper__active {
            .hover-reveal--content {
                display: block;
                opacity: 1;
            }
        }

        .hover-reveal--indicator {
            transition: transform $trs--base;
        }
    }

    // Facets
    &.hover-reveal__lang-switch {
        .hover-reveal--wrapper.hover-reveal--wrapper__active {
            .hover-reveal--indicator {
                transform: rotate(180deg);
            }
        }

        .hover-reveal--content {
            border-radius: $sz--border-radius-base;
            overflow: hidden;
            margin-top: 21px;
            left: -8px;
        }
    }

    // States
}
