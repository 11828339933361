.button {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 80px 0;

            @include mq('<=md') {
                padding: 60px 0;
            }
        }

        .button--row {
            justify-content: center;
        }

        .button--btn-icon {
            display: none;
            margin-left: 6px;
            transition: margin-left $trs--base;
        }

        .button--btn-link {
            color: $c--brand-secondary;

            .button--btn-icon {
                display: block;
            }

            @include has-focus() {
                color: $c--brand-dark-green;

                .button--btn-icon {
                    margin-left: 11px;
                }
            }
        }
    }

    // Facets
    &.button__left {
        .button--row {
            justify-content: flex-start;
        }
    }

    &.button__right {
        .button--row {
            justify-content: flex-end;
        }
    }

    // States
}
