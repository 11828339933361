/// Field styles
///
@mixin form-field--primary--floating-label($sz--input-padding-top, $sz--floating-label-padding-top, $sz--floating-label-padding-left, $sz--padding-x, $sz--border-width) {
    // Vars
    $fz--form-field: $fz--form-label;
    $lh--form-field: $lh--medium;

    // Support

    // Module
    & {
        transform: rotateZ(1turn); // Prevent jitter when animating floating-label

        .form-field--floating-label {
            padding-top: $sz--input-padding-top;
        }

        .form-field--floating-label {
            @include text-ellipsis;
            pointer-events: none;
            z-index: z-index('text');
            position: absolute;
            top: 0;
            left: $sz--floating-label-padding-left;
            padding: 15px 0;
            font-size: $fz--form-field;
            line-height: $lh--form-field;
            visibility: visible;
            backface-visibility: hidden;
            transform-origin: 0 50%;
            transition: transform $trs--base, color $trs--base, padding-top $trs--base;
        }

        .form-field--title-label {
            @include hide-visually;
        }
    }

    // States
    & {
        // Base
        @include form-field--apply-states(()) {
            @include form-field--primary--floating-label--base();
        }

        // Disabled
        @include form-field--apply-states(('disabled': true, 'focus': default)) {
            @include form-field--primary--floating-label--disabled();
        }

        // Error
        @include form-field--apply-states(('error': true, 'focus': default)) {
            @include form-field--primary--floating-label--error();
        }

        // Required
        @include form-field--apply-states(('required': true)) {
            @include form-field--primary--floating-label--required();
        }

        // Floating
        @include form-field--apply-states(('floating': true)) {
            @include form-field--primary--floating-label--floating($sz--floating-label-padding-top, $sz--floating-label-padding-left);
        }
    }
}

/// Base
///
@mixin form-field--primary--floating-label--base() {
    .form-field--floating-label {
        color: $c--label-text;
    }
}

/// Disabled
///
@mixin form-field--primary--floating-label--disabled() {
    .form-field--floating-label {
        color: $c--brand-light-blue-gray;
    }
}

/// Error
///
@mixin form-field--primary--floating-label--error() {
    .form-field--floating-label {
        color: $c--signal-error;
    }
}

/// Required
///
@mixin form-field--primary--floating-label--required() {
    .form-field--floating-label {
        @include form-field--is-required();
    }
}

/// Floating
///
@mixin form-field--primary--floating-label--floating($sz--floating-label-padding-top, $sz--floating-label-padding-left) {
    .form-field--floating-label {
        transform: scale(0.675);
        color: $c--brand-primary;
        padding-top: $sz--floating-label-padding-top;
    }
}
