.side-nav-panel {
    // Vars

    // Support

    // Module
    & {
        & {
            position: fixed;
            right: 0;
            bottom: 0;
            overflow: scroll;
            height: 100%;
            background-color: $c--brand-dark-blue;
            width: 100%;
            max-width: 391px;

            @include mq('<=sm') {
                max-width: 100%;
            }
        }

        .side-nav-panel--panel-content {
            margin-top: 80px;
            background-color: $c--brand-primary;

            @include mq('<=sm') {
                margin-top: 72px;
            }
        }

        .side-nav-panel--metanav {
            padding-top: 20px;
        }
    }

    // Facets
    &.side-nav-panel__reduced-header {
        .side-nav-panel--panel-content {
            @include mq('<=sm') {
                margin-top: 72px;
            }
        }
    }

    // States
}
