.aspect-ratio-image {
    // Vars
    // Support
    // Module
    & {
        width: 100%;
        padding-top: 56.25%;
        background-position: top left;
        background-repeat: no-repeat;
        background-size: contain;
    }

    // Facets
    // States
}
