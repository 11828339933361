/// Medium
///
@mixin btn--size-medium() {
    padding: 17px 23px;
    font-size: $fz--btn-medium;
    line-height: 1;

    @include mq('<=sm') {
        padding: 16px 23px;
    }

    @include ff--base-semi-bold();
}

/// Small
///
@mixin btn--size-small() {
    padding: 13px 23px 14px;
    font-size: $fz--btn-small;
    line-height: 1;

    @include ff--base-semi-bold();
}


/// Small
///
@mixin btn--size-even-smaller() {
    padding: 10px 23px 8px;
    font-size: $fz--btn-medium;
    line-height: 1;

    @include ff--base-semi-bold();
}

/// Medium bubble
///
@mixin btn--size-bubble-medium() {
    padding: 8px;
}

/// Small bubble
///
@mixin btn--size-bubble-small() {
    padding: 4px;
}
