.payment-overlay {
    // Vars

    // Support

    // Module
    & {
        & {
            position: fixed;
            width: 100%;
            height: 100%;
            padding: 80px 0;

            @include mq('<=md') {
                padding: 0;
            }
        }

        .payment-overlay--header-container {
            @include mq('<=sm') {
                //padding: 0 24px;
            }
        }

        .payment-overlay--close-button {
            pointer-events: auto;
            z-index: z-index('overlay-close-action');
            position: absolute;
            top: 24px;
            right: 24px;

            @include mq('<=md') {
                padding: 14px;
            }
        }

        .payment-overlay--close-icon {
            pointer-events: auto;
            color: $c--monochrome-black;
            cursor: pointer;
        }

        .payment-overlay--navigation-button {
            z-index: z-index('overlay-close-action');
            position: absolute;
            cursor: pointer;
            transform: translate3d(0, -50%, 0);
            top: calc(50% - 40px);
            background-color: $c--monochrome-white;
            padding: 12px;
            border-radius: 100%;
            color: $c--brand-primary;

            @include mq('<=md') {
                display: none;
            }
        }

        .payment-overlay--backdrop {
            z-index: z-index('overlay-backdrop');
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .payment-overlay--navigation-button.payment-overlay--navigation-button__prev {
            left: 32px;
        }

        .payment-overlay--navigation-button.payment-overlay--navigation-button__next {
            right: 32px;
        }

        .payment-overlay--content {
            z-index: z-index('overlay-content');
            position: relative;
            //top: 60px;
            max-width: 776px;
            margin: 0 auto;
            max-height: calc(100vh - 160px);
            overflow-y: scroll;
            background-color: $c--monochrome-white;
            //transform: translate3d(0, -50%, 0);

            border-radius: $sz--border-radius-base;

            padding: 36px 0 0;

            @include mq('<=md') {
                top: unset;
                height: 100%;
                max-height: 100vh;
                max-width: unset;
                margin-top: 0;
                border-radius: 0;
                transform: unset;
                padding-bottom: 36px;
            }
        }

        .payment-overlay--container {
            @include at-root(html, '.ie11') {
                display: block;
            }
        }

        .payment-overlay--pagination.payment-overlay--pagination {
            margin-top: 20px;

            @include mq('>md') {
                display: none;
            }
        }

        .payment-overlay--header-row {
            justify-content: space-between;
            align-items: center;
        }

        .payment-overlay--header-col {
            @include grid--span((ratio: 2/3));
            @include grid--span((ratio: 3/3, mq: '<=sm'));
        }

        .payment-overlay--header-logo-col {
            @include grid--span((ratio: 1/3));
            @include grid--span((ratio: 3/3, mq: '<=sm'));

            display: flex;
            justify-content: flex-end;

            @include mq('<=sm') {
                display: block;
            }
        }

        .payment-overlay--pagination-row {
            justify-content: space-between;
        }

        .payment-overlay--mobile-navigation {
            cursor: pointer;
            font-size: $fz--medium;
            line-height: 1;
            color: $c--brand-primary;
        }

        .payment-overlay--mobile-navigation-icon {
            position: relative;
            top: 3px;
            display: inline-block;
        }

        .payment-overlay--title {
            @include type--h2;
            margin-bottom: 0;

            @include mq('<=md') {
                font-size: 2.4rem;
                line-height: 1.08;
                margin-bottom: 0;
            }

            @include mq('<=sm') {
                margin-bottom: 24px;
            }
        }

        .payment-overlay--header-wrapper {
            position: relative;
            padding: 0 40px;
        }

        .payment-overlay--loader-wrapper {
            width: 100%;
            padding-bottom: 24px;
        }

        .payment-overlay--loader {
            margin: 0 auto;
        }
    }

    // Facets

    // States
}
