/// Form field facet
///
@mixin form-field--primary() {
    // Vars
    $sz--input-padding-top: 23px;
    $sz--input-padding-x: 0;
    $sz--input-padding-bottom: 7px;
    $sz--input-padding-left: 12px;
    $sz--floating-label-padding-top: 0;
    $sz--floating-label-padding-left: 12px;

    $sz--input-border-width: 1px;

    $fz--bool-label: $fz--small / 1rem * 10px; // Convert to px
    $lh--bool-label: 1.5;
    $sz--bool-box-gutter: 5px;
    $sz--bool-box: ($fz--bool-label + (2 * $sz--bool-box-gutter));
    $sz--bool-box-border: 2px;

    $sz--actions-icon-offset: 0;
    $sz--actions-icon-margin: 16px;
    $sz--actions-icon-width: 28px;

    // Support

    // Fields
    & {
        @include form-field--primary-all();

        &.form-field__hidden {
            @include form-field--primary-hidden();
        }

        &.form-field__input {
            .form-field--input {
                border-radius: 0;
            }

            @include form-field--primary-input(
                $sz--padding-top: $sz--input-padding-top,
                $sz--padding-x: $sz--input-padding-x,
                $sz--padding-bottom: $sz--input-padding-bottom,
                $sz--padding-left: $sz--input-padding-left,
                $sz--border-width: $sz--input-border-width
            );

            &.form-field__textarea {
                @include form-field--primary-textarea();
            }

            &.form-field__select {
                @include form-field--primary-select();
                @include form-field--primary-has-actions(
                    $sz--icon-margin: $sz--actions-icon-margin,
                    $sz--icon-offset: $sz--actions-icon-offset,
                    $sz--icon-width: $sz--actions-icon-width,
                    $sz--padding-top: $sz--input-padding-top,
                    $sz--padding-x: $sz--input-padding-x,
                    $sz--padding-bottom: $sz--input-padding-bottom,
                    $sz--padding-left: $sz--input-padding-left
                );
            }

            &.form-field__form-select {
                @include form-field--form-select();
                @include form-field--primary-has-actions(
                    $sz--icon-margin: $sz--actions-icon-margin,
                    $sz--icon-offset: $sz--actions-icon-offset,
                    $sz--icon-width: $sz--actions-icon-width,
                    $sz--padding-top: $sz--input-padding-top,
                    $sz--padding-x: $sz--input-padding-x,
                    $sz--padding-bottom: $sz--input-padding-bottom,
                    $sz--padding-left: $sz--input-padding-left
                );
            }

            &.form-field__secondary-select {
                @include form-field--secondary-select();
                @include form-field--primary-has-actions(
                    $sz--icon-margin: $sz--actions-icon-margin,
                    $sz--icon-offset: $sz--actions-icon-offset,
                    $sz--icon-width: $sz--actions-icon-width,
                    $sz--padding-top: $sz--input-padding-top,
                    $sz--padding-x: $sz--input-padding-x,
                    $sz--padding-bottom: $sz--input-padding-bottom,
                    $sz--padding-left: $sz--input-padding-left
                );
            }

            &.form-field__small {
                .form-field--input {
                    font-size: $fz--x-medium;

                    @include ff--base-bold;
                }
            }

            &.form-field__primary {
                .form-field--input {
                    background-color: $c--monochrome-white;
                    border: solid 1px $c--brand-primary;
                    color: $c--monochrome-white;
                }

                .form-field--action.form-field--action__arrow {
                    color: $c--brand-primary;
                }
            }

            &.form-field__secondary {
                .form-field--input.form-field--input.form-field--input {
                    background-color: $c--monochrome-white;
                    border: solid 1px $c--brand-secondary;
                    color: $c--brand-secondary;
                    padding: 12px 44px 12px 23px;

                    @include has-focus() {
                        border: solid 1px $c--brand-secondary;

                        @include background-opacity($c--brand-secondary, 0.1);
                    }
                }

                .form-field--action.form-field--action__arrow {
                    color: $c--brand-secondary;
                }
            }
        }

        &.form-field__bool {
            @include form-field--primary-bool(
                $fz--label: $fz--bool-label,
                $lh--label: $lh--bool-label,
                $sz--box: $sz--bool-box,
                $sz--box-border: $sz--bool-box-border
            );

            &.form-field__radio {
                @include form-field--primary-radio(
                    $sz--box: $sz--bool-box
                );
            }

            &.form-field__checkbox {
                @include form-field--primary-checkbox();
            }
        }

        &.form-field__cookie {
            @include form-field--primary-bool(
                $fz--label: $fz--btn-medium / 1rem * 10px,
                $lh--label: $lh--bool-label,
                $sz--box: $sz--bool-box,
                $sz--box-border: $sz--bool-box-border
            );

            &.form-field__radio {
                @include form-field--primary-radio(
                    $sz--box: $sz--bool-box
                );
            }

            &.form-field__checkbox {
                @include form-field--primary-cookie-checkbox();
            }
        }
    }

    // Addons
    & {
        &.form-field__floating-label {
            @include form-field--primary--floating-label(
                $sz--input-padding-top: $sz--input-padding-top,
                $sz--floating-label-padding-top: $sz--floating-label-padding-top,
                $sz--floating-label-padding-left: $sz--floating-label-padding-left,
                $sz--padding-x: $sz--input-padding-x,
                $sz--border-width: $sz--input-border-width
            );
        }

        &.form-field__has-actions {
            @include form-field--primary-has-actions(
                $sz--icon-margin: $sz--actions-icon-margin,
                $sz--icon-offset: $sz--actions-icon-offset,
                $sz--icon-width: $sz--actions-icon-width,
                $sz--padding-top: $sz--input-padding-top,
                $sz--padding-x: $sz--input-padding-x,
                $sz--padding-bottom: $sz--input-padding-bottom,
                $sz--padding-left: $sz--input-padding-left
            );
        }
    }
}
