.legal {
    // Vars

    // Support

    // Module
    & {
        & {
            @include at-root('html', '.cookie-not-accepted') {
                padding-bottom: 100px;
            }
        }

        .legal--row {
            justify-content: space-between;
            padding: 30px 0;

            @include mq('<=md') {
                padding-bottom: 8px;
            }

            @include mq('<=sm') {
                padding-top: 0;
            }
        }

        .legal--wrapper-col {
            @include grid--span((ratio: 12/12));
        }

        .legal--col {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include grid--span((ratio: 12/12));
        }

        .legal--list {
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            height: 100%;
            max-height: 19px;

            @include mq('<=lg') {
                display: inline-block;
                max-height: unset;
            }
        }

        .legal--divider {
            border-top: $divider-border;

            @include mq('<=sm') {
                border-top: none;
            }
        }

        .legal--list-item {
            display: inline-flex;
            height: 100%;
            padding: 2px 12px 2px 0;
            margin-right: 12px;
            margin-bottom: 20px;
            border-right: $divider-border;

            &:first-child {
                padding-left: 0;
            }

            &:nth-last-child(2),
            &:last-child {
                padding-right: 0;
                border-right: none;
            }

            @include mq('<=lg') {
                display: inline-flex;

                &:nth-last-child(2) {
                    padding-right: 12px;
                    border-right: $divider-border;
                }
            }
        }

        .legal--copy {
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
            padding-right: 0;
            margin-right: 0;

            color: $c--brand-blue-grey;
            font-size: $fz--x-medium;
            line-height: 1;
            justify-self: flex-end;

            @include mq('<=lg') {
                display: inline-flex;
            }
        }
    }

    // Facets

    // States
}
