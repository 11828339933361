.anchor-link {
    // Vars

    // Support

    // Module
    & {
        & {
            position: relative;
        }

        .anchor-link--anchor {
            position: absolute;
            margin-top: -24px;
            top: -$sz--header-height;
        }
    }

    // Facets

    // States
}
