&.content-teaser__industry-teaser {
    $image-width: 338px;
    $image-width-md: 294px;

    & {
        box-shadow: none;
        transition: box-shadow $trs--base;

        @include mq('<=sm') {
            padding-top: 0;
        }

        @include has-focus() {
            @include shadow-active;
        }
    }

    .content-teaser--image-wrapper {
        overflow: hidden;
        border-radius: $sz--border-radius-base;

        @include at-root(html, '.ie11') {
            video {
                position: absolute;
                left: 50%;
                top: 50%;
                height: calc(100% + 20px);
                width: calc(100% + 20px);
                transform: translateX(-50%) translateY(-50%);
            }
        }
    }

    .content-teaser--row {
        height: 100%;
        width: 100%;

        @include at-root(html, '.ie10') {
            max-width: 100%;
        }

        @include at-root(html, '.ie11') {
            max-width: 100%;
        }

        @include mq('<=sm') {
            flex-direction: column;
        }
    }

    .content-teaser--content {
        @include mq('<=md') {
            max-width: 100%;
        }
    }

    .content-teaser--content-wrapper {
        padding: 40px;

        @include mq('<=md') {
            padding: 40px 24px;
        }
    }

    .content-teaser--left-pane {
        @include at-root(html, '.ie10') {
            max-width: $image-width;
            overflow: hidden;

            @include mq('<=md') {
                max-width: $image-width-md;
            }
        }

        @include at-root(html, '.ie11') {
            max-width: $image-width;
            overflow: hidden;

            @include mq('<=md') {
                max-width: $image-width-md;
            }
        }

        @include mq('<=sm') {
            @include grid--span((ratio: 4/4));
            max-height: 184px;

            &::before {
                display: none;
            }
        }

        @include mq('<=xs') {
            flex: 0 0 100%;
        }
    }

    .content-teaser--right-pane {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-left: -1px;

        @include mq('<=md') {
            padding-left: 0;
            padding-right: 0;
        }

        @include at-root(html, '.ie10') {
            max-width: calc(100% - #{ $image-width });
            overflow: hidden;

            @include mq('<=md') {
                max-width: calc(100% - #{ $image-width-md });
            }
        }

        @include at-root(html, '.ie11') {
            max-width: calc(100% - #{ $image-width });
            overflow: hidden;

            @include mq('<=md') {
                max-width: calc(100% - #{ $image-width-md });
            }
        }

        @include mq('<=sm') {
            @include grid--span((ratio: 4/4));

            padding: 0;
        }

        @include mq('<=xs') {
            flex: 0 0 100%;
        }
    }

    .content-teaser--title {
        h3 {
            @include type--h3;
            margin-bottom: 12px;

            @include mq('<=md') {
                margin-bottom: 12px;
            }
        }

        @include mq('<=sm') {
            padding: 24px 24px 12px;
        }
    }

    .content-teaser--content-text {
        @include mq('<=sm') {
            padding: 0 24px;
        }
    }

    .content-teaser--links {
        @include mq('<=sm') {
            display: flex;
            justify-content: center;
            border-top: $divider-border;
            padding: 24px;
        }
    }
}
