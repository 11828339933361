
&.navigation-entry__nav-preview {
    &.navigation-entry__root {
        .navigation-entry--label {
            color: $c--brand-primary;
        }
    }

    &.navigation-entry__child {
        .navigation-entry--label {
            color: $c--brand-blue-grey;
            padding: 10px 30px 9px;
        }

        &:last-child {
            .navigation-entry--label {
                margin-bottom: 6px;
            }
        }
    }

    .navigation-entry--child {
        margin: 0;
    }

    .navigation-entry--label {
        margin-bottom: 0;
        padding: 16px 20px 14px;
        line-height: $lh--x-medium;
        background-color: $c--monochrome-white;
        transition: background-color $trs--base;


        @include has-focus() {
            background-color: $c--brand-very-light-blue;
        }
    }

    .navigation-entry--label.navigation-entry--label__active {
        background-color: $c--monochrome-light-gray;
    }
}
