/// Web-static folder
///
$web-static: '../..';

/// BEM options
///
$bem-element-separator: '--';
$bem-modifier-separator: '__';

/// Media queries and breakpoints
/// See: `util/mq.scss`

/// Colors
/// See: `util/colors.scss`

/// Layers (z-index)
///
$layers: (
    content: 0,
    text: 1,
    infographic-header--content: 1,
    double-button--content: 1,
    split-card-wrapper-content: 1,
    split-card-content: 2,
    teaser-card-triangle: 2,
    content-teaser-triangle: 2,
    content-teaser-right-pane: 3,
    content-teaser-left-pane: 4,
    gallery--btn: 2,
    nba-primary-background: 3,
    nba-primary-content: 4,
    hover-reveal--content: 8,
    page-up-button: 10,
    dot-loader: 10,
    breadcrumbs: 10,
    //Ecom Report
    ecom-border:32,
    ecom-border-mobile:20,
    ecom-label: 33,
    // Overlay
    overlay-backdrop: 23,
    overlay-content: 24,
    overlay-close-action: 25,
    //Navigation
    nav-preview-backdrop: 26,
    main-nav: 27,
    meta-nav: 28,
    nav-preview: 29,
    language-switch: 30,
    mobile-navbar: 34,
    reduced-nav: 35,
    // Cookiebanner
    cookiebanner: 39,
    // Payment Overlay
    top-overlay-backdrop: 40,
    top-overlay-content: 42,
);

/// Fonts
/// See: `base/type.scss`

/// Sizes & spacing
/// See: `base/spacing.scss`

/// Transitions & animations
///
$trs--base-speed: 200ms;
$trs--base-fn: ease;
$trs--base: $trs--base-speed $trs--base-fn;

$trs--common-property-list: color, border-color, background-color, opacity, transform;
$trs--common-props: transition-props($trs--base, $trs--common-property-list...);

// Font Sizes

$fz--xx-large: 3.2rem;
$fz--x-large: 2.6rem;
$fz--xx-medium: 2.4rem;
$fz--medium-larger: 1.8rem;
$fz--medium: 1.6rem;
$fz--form-label: 1.6rem;
$fz--x-medium: 1.5rem;
$fz--small: 1.4rem;
$fz--x-small: 1.2rem;

$fz--lead: $fz--xx-medium;
$fz--copy: 1.8rem;
$fz--btn-medium: 1.7rem;
$fz--btn-small: 1.5rem;
$fz--copy-secondary: $fz--medium;
$fz--copy-tertiary: $fz--small;

$lh--x-large: 1.08;
$lh--large: 1.38;
$lh--x-medium: 1.18;
$lh--medium: 1.38;
$lh--small: 1.29;
$lh--x-small: 1.3;
$lh--xx-small: 1.38;

$lh--lead: 1.25;
$lh--copy: 1.44;
$lh--copy-secondary: $lh--medium;
$lh--copy-tertiary: $lh--small;

// Sizes
$sz--list-icon-width: 6px;
$sz--list-icon-gutter: 18px;
$sz--list-padding: ($sz--list-icon-width + $sz--list-icon-gutter);
$sz--list-vertical-spacing: 12px;

/// Border radius
$sz--border-radius-base: 3px;
$sz--border-width-base: 2px;

$sz--btn-border: 1px;

// Header
$sz--header-height: 73px;

// Brand specific stuff
$skew: -10deg;
$hover-reveal: 12px;

// Hamburger SCSS
$hamburger-layer-width: 24px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 6px;
$hamburger-padding-x: 12px;
$hamburger-padding-y: 0;
$hamburger-layer-color: #ffffff;
$hamburger-hover-opacity: 1;
$hamburger-active-layer-color: #ffffff;
$hamburger-active-hover-opacity: 1;
$hamburger-types: (
    spin,
);

$scrollbar-offset: 16px;
$grid--base-container-sizes: (
    (mq: 'xs', container-size: none),
    (mq: 'sm', container-size: $screen-sm-min),
    (mq: 'md', container-size: ($screen-md-min - 40px)),
    (mq: 'lg', container-size: ($screen-lg-min - $scrollbar-offset - 8px)),
    (mq: 'xl', container-size: ($screen-xl-min - $scrollbar-offset - 4px - 16px)), //(mq: 'xxl', container-size: ($screen-xxl-min - $scrollbar-offset - 8px)),
) !global;
$grid--base-spacing: (
    (mq: 'xs', gutter: 24px, edge: 24px),
    (mq: 'sm', gutter: 24px, edge: 24px),
    (mq: 'md', gutter: 32px, edge: 32px),
    (mq: 'lg', gutter: 32px, edge: 32px),
    (mq: 'xl', gutter: 32px, edge: 32px), //(mq: 'xxl', gutter: 40px, edge: 40px),
) !global;
