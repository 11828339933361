.mobile-nav {
    // Vars

    // Support

    // Module
    & {
        & {
            width: 100%;
        }

        .mobile-nav--navbar.mobile-nav--navbar__reveal-visible {
            visibility: hidden;
        }

        .mobile-nav--navbr__spacer {
            visibility: hidden;
        }
    }

    // Facets

    &.mobile-nav__overlay-active {
        .mobile-nav--scroll-reveal {
            top: 0;
            display: block;
            opacity: 1;
        }
    }

    // States
}
