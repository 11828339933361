.team-member {
    // Vars

    // Support

    // Module
    & {
        & {
            display: flex;
            flex-direction: column;

            @include shadow-active;
        }

        .team-member--content {
            padding: 32px;

            @include mq('<=md') {
                padding: 24px 26px;
            }
        }

        .team-member--name {
            @include type--h3;

            margin-bottom: 4px;

            @include mq('<=md') {
                margin-bottom: 4px;
            }
        }

        .team-member--description {
            font-size: $fz--medium;
        }

        .team-member--image {
            display: block;
            height: 100%;
            width: 100%;

            @supports (object-fit: cover) {
                object-fit: cover;
                object-position: 50% 50%;
            }
        }
    }

    // Facets

    // States
}
