.solution-overview-category {
    // Vars

    // Support
    @mixin theme-category($color) {
        .solution-overview-category--title-wrapper {
            background: $color;
        }

        .solution-overview-category--group {
            border-bottom: solid 1px $color;

            &:last-child {
                border-bottom: none;
            }
        }
    }

    // Module
    & {
        & {
            background-color: $c--monochrome-white;
            box-shadow: none;
            border-radius: $sz--border-radius-base;
            overflow: hidden;
            transition: box-shadow $trs--base;

            @include has-focus() {
                @include shadow-active;
            }
        }

        .solution-overview-category--title-wrapper {
            display: flex;
            justify-content: center;
            padding: 24px 0;
        }

        .solution-overview-category--title {
            font-size: $fz--btn-medium;
            color: $c--brand-primary;
            font-weight: $fw--semi-bold;
        }

        .solution-overview-category--group {
            padding: 24px;
            border-bottom: $divider-border;
        }
    }

    // Facets
    &.solution-overview-category__blue {
        @include theme-category($c--spot-light-blue);
    }

    &.solution-overview-category__green {
        @include theme-category($c--spot-light-green);
    }

    &.solution-overview-category__yellow {
        @include theme-category($c--spot-wheat);
    }

    // States
}
