&.grid__debug {
    // Vars
    $scrollbar-offset: 16px;
    $grid--base-container-sizes: (
        (mq: 'xs', container-size: none),
        (mq: 'sm', container-size: $screen-sm-min),
        (mq: 'md', container-size: ($screen-md-min - 40px)),
        (mq: 'lg', container-size: ($screen-lg-min - $scrollbar-offset - 8px)),
        (mq: 'xl', container-size: ($screen-xl-min - $scrollbar-offset - 4px - 16px)), //(mq: 'xxl', container-size: ($screen-xxl-min - $scrollbar-offset - 8px)),
    ) !global;
    $grid--base-spacing: (
        (mq: 'xs', gutter: 24px, edge: 24px),
        (mq: 'sm', gutter: 24px, edge: 24px),
        (mq: 'md', gutter: 32px, edge: 32px),
        (mq: 'lg', gutter: 32px, edge: 32px),
        (mq: 'xl', gutter: 32px, edge: 32px), //(mq: 'xxl', gutter: 40px, edge: 40px),
    ) !global;

    $debug-grid--column-mq: (
        (
            mq: '>lg',
            column: 69px,
            gutter: 32px,
        ),
        (
            mq: '<=lg',
            column: 46px,
            gutter: 32px,
        ),
        (
            mq: '<=sm',
            column: 106px,
            gutter: 24px,
        )
    );
    $debug-grid--gutter: 32px;

    // Module
    & {
        @include grid--root();

        .grid--container {
            position: relative;

            &::after {
                content: '';
                pointer-events: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                @each $gridConfig in $debug-grid--column-mq {
                    $mq: map-get($gridConfig, 'mq');
                    $column: map-get($gridConfig, 'column');
                    $gutter: map-get($gridConfig, 'gutter');

                    $debug-grid--background-size: $column + $gutter; // Support

                    @include mq($mq) {
                        background-image: linear-gradient(to right, rgba($c--brand-primary, 0.1) $column, transparent $gutter);
                        background-size: $debug-grid--background-size 100%;
                        background-position-x: $gutter;
                    }
                }
            }
        }

        .grid__base {
            .grid--container {
                &::after {
                    display: none;
                }
            }
        }
    }

    // Facets

    // States
}
