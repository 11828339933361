.content-teaser {
    // Vars

    // Support

    // Module
    & {
        & {
            position: relative;

            box-shadow: unset;
            transition: box-shadow $trs--base;
            border-radius: $sz--border-radius-base;
            overflow: hidden;

            @include has-focus() {
                @include shadow-active;
            }

            @include grid--root();
        }

        .content-teaser--image-wrapper {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;

            img, video {
                display: block;
                height: 100%;
                width: 100%;

                @supports (object-fit: cover) {
                    object-fit: cover;
                    object-position: 50% 50%;
                }
            }
        }

        .content-teaser--content-wrapper {
            padding: 80px 80px 80px 0;
        }

        .content-teaser--col {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            overflow: hidden;
            position: relative;
        }

        .content-teaser--row.content-teaser--row {
            @include mq('<=sm') {
                display: block;
            }
        }

        .content-teaser--right-pane {
            display: block;
            background-color: $c--monochrome-white;
            //flex: 0 0 calc(100% - 331px);

            @include grid--span((ratio: 7/12));
        }

        .content-teaser--left-pane {
            display: block;
            overflow: hidden;

            @include grid--span((ratio: 5/12));

            &::before {
                content: '';
                pointer-events: none;
                z-index: z-index('content-teaser-triangle');
                position: absolute;
                top: 0;
                right: 2px;
                width: 100%;
                height: calc(100% + 2px);
                transform: skew($skew) translateX(100%);
                transform-origin: right top;
                transition: transform $trs--base;
                background-color: $c--monochrome-white;
            }
        }

        .content-teaser--content {
            width: 100%;
            max-width: 100%;
            font-size: $fz--copy;
            line-height: $lh--copy;
            overflow: hidden;

            margin-bottom: 0;

            @include mq('<=md') {
                max-width: 284px;
                font-size: $fz--copy-secondary;
                line-height: $lh--copy-secondary;
            }
        }

        .content-teaser--content-text {
            display: block;
            color: $c--brand-blue-grey;
            width: 100%;
            max-width: 100%;
            overflow: hidden;
        }

        .content-teaser--links {
            margin-top: 24px;

            &:empty {
                display: none;
            }
        }
    }

    // Facets
    @import './facets/content-teaser__specialist';
    @import './facets/content-teaser__payment-solution-intro';
    @import './facets/content-teaser__article-teaser';
    @import './facets/content-teaser__industry-detail-header';
    @import './facets/content-teaser__industry-teaser';

    // States

    &.content-teaser__active {
        .content-teaser--left-pane {
            &::before {
                transform: skew($skew) translateX(100%) translateX(-#{$hover-reveal});
            }
        }
    }
}
