.breadcrumb-background {
    // Vars

    // Support

    // Module

    // Facets
    &.breadcrumb-background__dark-background {
        li {
            background-color: $c--monochrome-white;
            padding: 3px 3px 1px;

            @include mq('<=md') {
                padding: 3px;
            }
        }
    }

    // States
}
