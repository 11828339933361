.partner-list {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0;

            @include mq('<=md') {
                padding: 30px 0;
            }
        }

        .partner-list--title-row {
            margin-bottom: 32px;

            @include mq('<=md') {
                margin-bottom: 24px;
            }
        }

        .partner-list--title {
            @include type--h2;
            margin-bottom: 0;

            @include mq('<=md') {
                margin-bottom: 0;
            }
        }

        .partner-list--item {
            display: flex;

            @include mq('>sm') {
                &:first-child,
                &:nth-child(2) {
                    .partner-list--item-content {
                        border-top: solid 1px $c--brand-light-blue-gray;
                    }
                }
            }

            @include mq('<=sm') {
                &:first-child {
                    .partner-list--item-content {
                        border-top: solid 1px $c--brand-light-blue-gray;
                    }
                }
            }

            @include grid--span((ratio: 6/12));
            @include grid--span((ratio: 12/12, mq: '<=sm'));
        }

        .partner-list--item-content {
            padding: 32px 0;
            border-bottom: solid 1px $c--brand-light-blue-gray;
            max-width: 100%;

            @include mq('<=md') {
                padding: 24px 0;
            }
        }
    }

    // Facets

    // States
}
