.navbar {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 16px 0;
            border-bottom: $divider-border;
            width: 100%;
            background-color: $c--monochrome-white;
        }
    }

    // Facets
    &.navbar__reduced {
        & {
            padding: 16px 0;
        }
    }

    // States
}
