.lazy-video {
    // Vars

    // Support

    // Module
    & {
        .lazy-video--element {
            width: 100%;
            height: 100%;
        }
    }

    // Facets
    & {
        &.lazy-video__animated-infographics-header {
            position: absolute;
            top: 0;
            left: 0;
        }

        &.lazy-video__industry-teaser {
            height: 100%;
        }
    }

    // States
}
