.article-teaser {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0;

            @include mq('<=md') {
                padding: 30px 0;
            }
        }

        .article-teaser--content-row.article-teaser--content-row {
            justify-content: center;

            @include mq('<=sm') {
                display: block;
            }
        }

        .article-teaser--component-link {
            color: $c--brand-blue-grey;
        }

        .article-teaser--teaser {
            box-shadow: none;
            transition: box-shadow $trs--base;
        }

        .article-teaser--title {
            @include type--h2;
            margin-bottom: 40px;
            color: $c--monochrome-white;

            @include mq('<=md') {
                margin-bottom: 40px;
            }
        }

        .article-teaser--teaser-title {
            @include type--h3;
            margin-bottom: 12px;
            color: $c--brand-primary;

            @include mq('<=md') {
                margin-bottom: 12px;
            }

            @include mq('<=sm') {
                margin-bottom: 0;
            }
        }

        .article-teaser--content-col {
            @include grid--span((ratio: 10/12));
            @include grid--span((ratio: 12/12, mq: '<=lg'));
            @include grid--span((ratio: 3/4, mq: '<=sm'));
            @include grid--span((ratio: 4/4, mq: '<=xs'));
        }

        .article-teaser--content {
            color: $c--brand-blue-grey;
            display: block;
            max-width: 100%;
            flex-shrink: 1;

            @include type--copy-secondary;
            margin-bottom: 0;

            @include mq('<=md') {
                margin-bottom: 0;
            }
        }
    }

    // Facets

    // States
}
