.navbar-content {
    // Vars

    // Support

    // Module
    & {
        & {
            width: 100%;
        }

        .navbar-content--container.navbar-content--container {
            @include mq('<=lg') {
                max-width: unset;
            }
        }

        .navbar-content--row {
            align-items: center;
            justify-content: space-between;
        }

        .navbar-content--brand-link {
            display: flex;
        }

        .navbar-content--list-item {
            display: inline-block;

            &:last-child {
                margin-right: -20px;
            }

            @include mq('<=lg') {
                &:last-child {
                    margin-right: -12px;
                }
            }
        }
    }

    // Facets

    // States
}
