.ecom-report {
    // Vars

    // Support

    // Module
    & {
        .ecom-report--label {
            z-index: z-index('ecom-label');
            position: fixed;
            left: 25px;
            top: 50%;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            font-size: $fz--medium;
            line-height: $lh--medium;
            padding: 2px 6px;
            color: $c--monochrome-white;
            background: $c--spot-watermelon;
            transform: translate3d(-50%, -50%, 0) rotateZ(-90deg);
            transform-origin: center;

            @include mq('<=md') {
                font-size: $fz--small;
                line-height: $lh--small;
                left: 11px;
            }
        }
    }

    // Facets

    // States
}
