.page {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        flex: 1 0 auto;

        .page--main {
            flex: 1 0 auto;
        }
    }

    // Facets

    // States
}
