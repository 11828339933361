.cookie-setting-message {
    // Vars

    // Support

    // Module
    & {
        & {
            padding-bottom: 24px;
        }

        .cookie-setting-message--description {
            color: $c--monochrome-silver;
            font-size: $fz--x-small;
        }


        .cookie-setting-message--link.cookie-setting-message--link {
            font-size: $fz--x-small;
        }
    }

    // Facets

    // States
}
