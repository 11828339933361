.contact-form-success {
    // Vars

    // Support

    // Module
    & {
        .contact-form-success--title {
            @include type--h2;
            margin-bottom: 24px;
        }

        .contact-form-success--lead {
            @include type--copy;
            color: $c--brand-blue-grey;
        }

        .contact-form-success--action-row {
            justify-content: flex-end;
        }
    }

    // Facets

    // States
}
