&.accordion-item__footer {
    .accordion-item--title-wrapper {
        @include mq('<=sm') {
            padding: 14px;
        }
    }

    & {
        width: 100%;

        &:last-child {
            border-bottom: $divider-border;
        }

        .accordion-item--indicator {
            color: $c--brand-primary;
        }
    }
}
