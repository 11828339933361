.main-nav {
    // Vars

    // Support

    // Module
    & {
        & {
            z-index: z-index('main-nav');
            position: relative;
            min-height: $sz--header-height;
        }
    }

    // Facets
    &.main-nav__hide-reduced {
        .main-nav--scroll-reveal {
            display: none;
        }
    }

    // States
}
