.faq-group {
    // Vars

    // Support

    // Module
    & {
        & {
            margin: 40px 0;

            @include mq('<=md') {
                margin: 30px 0;
            }

            &:first-child {
                margin-top: 0;

                @include mq('<=md') {
                    margin-top: 0;
                }
            }

            &:last-child {
                margin-bottom: 0;

                @include mq('<=md') {
                    margin-bottom: 0;
                }
            }
        }


        .faq-group--title.faq-group--title {
            font-size: $fz--xx-medium;
            margin-bottom: 24px;
        }
    }

    // Facets

    // States
}
