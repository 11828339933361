.page-up-button {
    // Vars
    // Support
    // Module
    & {
        & {
            @include shadow-page-up;
            z-index: z-index('page-up-button');
            position: fixed;
            bottom: 24px;
            right: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 60px;
            background-color: $c--brand-secondary;
            border-radius: 50%;
            color: $c--monochrome-white;
            opacity: 1;
            transform: translateY(0);
            transition: all 500ms;
            transition-property: opacity, transform;
            will-change: opacity, transform;
            cursor: pointer;

            &:hover {
                @include shadow-page-up-active;
                background-color: $c--brand-dark-green;
            }
        }

        &.page-up-button__is-hidden {
            transform: translateY(100%);
            opacity: 0;
        }
    }

    // Facets
    // States
}
