.article-overview {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((
            row: 'category-filter-wrapper',
            cols: 'category-filter',
        ));
        @include grid--space((
            gutter: (16px, 16px),
            row: 'category-filter-wrapper',
            cols: 'category-filter',
        ));

        .article-overview--filter-container {
            margin-bottom: 40px;
        }

        .article-overview--filter-row {
            justify-content: center;
        }

        .article-overview--filter-col {
            @include grid--span((ratio: 8/12));
            @include grid--span((ratio: 12/12, mq: '<=md'));
        }

        .article-overview--category-filter-wrapper {
            display: flex;
            justify-content: flex-start;

            @include mq('<=sm') {
                flex-direction: column;
            }
        }
    }

    // Facets

    // States
}
