/// Field styles
///
@mixin form-field--primary-cookie-checkbox() {
    // Vars
    $sz--checkbox-icon: 16px;
    $sz--checkbox-border-radius: $sz--border-radius-base;

    // Module
    & {
        & {
            overflow: hidden;
        }

        .form-field--input-container {
            margin-right: 4px;

            @include at-root(html, '.ie11') {
                display: inline-block;
            }
        }

        .form-field--row {
            margin-top: 0;

            @include at-root(html, '.ie11') {
                display: block;
            }
        }

        .form-field--label {
            font-size: $fz--x-medium;
            line-height: 1;
            position: relative;
            top: -2px;
        }

        .form-field--input {
            ~ .form-field--label-wrap {
                .form-field--box {
                    &,
                    &::after,
                    &::before {
                        border-radius: $sz--checkbox-border-radius;
                    }
                }

                .form-field--box-icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-left: ($sz--checkbox-icon / -2);
                    margin-top: ($sz--checkbox-icon / -2);
                    width: $sz--checkbox-icon;
                    height: $sz--checkbox-icon;
                }
            }
        }
    }

    // States
}
