.footer {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 80px 0 32px;
            border-top: $divider-border;

            @include mq('<=md') {
                padding: 60px 0 32px;
            }

            @include grid--root((row: 'logo-list', cols: 'logo-list-item'));
            @include grid--space((
                gutter: (24px 24px),
                row: 'logo-list', cols: 'logo-list-item',)
            );

            @include grid--root((row: 'social-list', cols: 'social-list-item'));
            @include grid--space((
                gutter: (16px 16px),
                row: 'social-list',
                cols: 'social-list-item',
                mq: '>sm'
            ));
            @include grid--space((
                gutter: (24px, 24px),
                row: 'social-list',
                cols: 'social-list-item',
                mq: '<=sm'
            ));
        }

        .footer--address-col {
            overflow: hidden;

            @include grid--span((ratio: 3/12));
            @include grid--span((ratio: 3/12, mq: '<=md'));
            @include grid--span((ratio: 12/12, mq: '<=sm'));
        }

        .footer--logo {
            display: flex;
            max-width: 100%;

            margin-bottom: 16px;

            @include mq('<=sm') {
                margin-bottom: 22px;
            }
        }

        .footer--address {
            display: block;
            line-height: 1.8;
            font-size: $fz--x-medium;
        }

        .footer--address-label.footer--address-label {
            line-height: 1.8;
            font-size: $fz--x-medium;
            margin-bottom: 8px;
        }

        .footer--address-link {
            margin-bottom: 12px;
        }

        .footer--categories-wrapper-col {
            @include grid--span((ratio: 9/12));
            @include grid--span((ratio: 9/12, mq: '<=md'));
            @include grid--span((ratio: 12/12, mq: '<=sm'));

            @include mq('<=sm') {
                margin-top: 24px;
            }
        }

        .footer--categories-row {
            justify-content: flex-end;
        }

        .footer-category-col {
            display: flex;
            justify-content: center;

            @include grid--span((ratio: 3/9));
            @include grid--span((ratio: 3/9, mq: '<=md'));
            @include grid--span((ratio: 12/12, mq: '<=sm'));

            @include mq('<=sm') {
                justify-content: flex-start;

                &:first-child {
                    .footer--category-group {
                        border-top: $divider-border;
                    }
                }
            }
        }

        .footer--category-title {
            margin-bottom: 12px;
            font-size: $fz--x-medium;
            color: $c--brand-primary;
            line-height: 1;

            @include mq('<=sm') {
                margin-bottom: 0;
            }
        }

        .footer--category-item {
            margin-bottom: 12px;
            font-size: $fz--x-medium;
        }

        .footer--bottom-row {
            margin-top: 32px;
        }

        .footer--link {
            display: block;
            color: $c--brand-blue-grey;
            transition: color $trs--base;
            line-height: 1;

            @include has-focus() {
                color: $c--brand-primary;
            }
        }

        .footer--social-list-wrap {
            display: flex;
            align-items: center;
        }

        .footer--social-link {
            color: $c--brand-blue-grey;
            transition: transition-props($trs--base, color);

            @include mq('<=sm') {
                flex: 0 0 100%;
            }

            @include has-focus {
                color: $c--brand-dark-blue;
            }
        }

        .footer--logo-link {
            display: flex;
            align-items: center;
        }

        .footer--logo-list-wrap {
            flex: 1 1 auto;
            align-items: center;

            @include mq('>sm') {
                justify-content: flex-end;
            }

            @include mq('<=sm') {
                order: -1;
                margin-bottom: 24px;
                flex: 0 0 100%;
            }
        }

        .footer--logo-list {
            align-items: center;

            @include mq('>sm') {
                justify-content: flex-end;
            }
        }

        .footer--logo-img {
            @include mq('<=sm') {
                max-width: 80px;
            }
        }

        .footer--social-icon {
            // stretch the icons on mobile
            @include mq('<=sm') {
                @include size(40px);
            }
        }
    }

    // Facets

    // States
}
