&.content-teaser__article-teaser {
    $image-width: 516px;
    $image-width-md: 363px;

    & {
        @include mq('<=sm') {
            padding-top: 0;
        }
    }

    .content-teaser--row {
        @include mq('<=sm') {
            display: block;
        }
    }

    .content-teaser--content {
        @include mq('<=sm') {
            max-width: 100%;
        }
    }

    .content-teaser--left-pane {
        flex: 0 0 $image-width;

        @include mq('<=md') {
            flex: 0 0 $image-width-md;
        }

        @include mq('<=sm') {
            @include grid--span((ratio: 4/4));
            max-height: 184px;

            &::before {
                display: none;
            }
        }

        @include mq('<=xs') {
            flex: 0 0 100%;
        }
    }

    .content-teaser--content-wrapper {
        padding: 63px 40px 63px 48px;

        @include mq('<=md') {
            padding: 40px 28px;
        }

        @include mq('<=sm') {
            padding: 0;
        }
    }

    .content-teaser--right-pane {
        display: block;
        flex: 0 0 calc(100% - #{ $image-width });
        //max-width: calc(100% - #{ $image-width });

        @include mq('<=md') {
            flex: 0 0 calc(100% - #{ $image-width-md });
        }

        @include mq('<=sm') {
            @include grid--span((ratio: 4/4));

            justify-content: flex-start;
            padding: 0;
        }

        @include mq('<=xs') {
            flex: 0 0 100%;
        }
    }

    .content-teaser--title {
        @include mq('<=sm') {
            padding: 24px 24px 12px;
        }
    }

    .content-teaser--content-text {
        @include mq('<=sm') {
            padding: 0 24px;
        }
    }

    .content-teaser--links {
        @include mq('<=sm') {
            display: flex;
            justify-content: center;
            border-top: $divider-border;
            padding: 24px;
        }
    }
}
