.testaccount-overlay {
    // Vars

    // Support

    // Module
    & {
        & {
            position: fixed;
            width: 100%;
            height: 100%;
            padding: 80px 0;

            @include mq('<=md') {
                padding: 0;
            }
        }


        .testaccount-overlay--backdrop {
            z-index: z-index('overlay-backdrop');
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .testaccount-overlay--content-wrapper {
            z-index: z-index('overlay-content');
            position: relative;
            //top: 60px;
            height: 80vh;
            max-width: 776px;
            margin: 0 auto;
            max-height: calc(100vh - 160px);
            overflow-y: scroll;
            background-color: $c--monochrome-white;
            //transform: translate3d(0, -50%, 0);

            border-radius: $sz--border-radius-base;

            padding: 36px 0 0;

            @include mq('<=md') {
                top: unset;
                height: 100vh;
                max-height: 100vh;
                max-width: unset;
                margin-top: 0;
                border-radius: 0;
                transform: unset;
                padding-bottom: 36px;
            }
        }

        .testaccount-overlay--row {
            justify-content: center;
        }

        .testaccount-overlay--col {
            @include grid--span((ratio: 12/12));
        }

        .testaccount-overlay--content {
            padding: 0 50px;

            @include mq('<=md') {
                padding: 0 24px;
            }
        }

        .testaccount-overlay--frame {
            width: 100%;
            height: 70vh;

            @include mq('<=md') {
                height: 100vh;
            }
        }
    }

    // Facets

    // States
}
