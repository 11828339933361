.article-list {
    // Vars

    // Support

    // Module
    & {
        .article-list--row {
            justify-content: center;
        }

        .article-list--divider {
            border-top: $divider-border;
        }

        .article-list--header-col {
            @include grid--span((ratio: 8/12));
            @include grid--span((ratio: 12/12, mq: '<=md'));
        }

        .article-list--col {
            margin: 12px 0;

            @include mq('<=md') {
                margin: 10px 0;
            }

            border-radius: $sz--border-radius-base;

            @include grid--span((ratio: 8/12));
            @include grid--span((ratio: 12/12, mq: '<=md'));
        }

        .article-list--item.article-list--item {
            box-shadow: none;
            transition: box-shadow $trs--base;
            padding: 0 24px;

            @include has-focus() {
                @include shadow-active;
            }

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .article-list--cta-row {
            margin-top: 24px;

            @include mq('<=md') {
                margin-top: 30px;
            }
        }

        .article-list--title {
            @include type--h3;
            margin-top: 40px;
            margin-bottom: 32px;

            @include mq('<=md') {
                margin-bottom: 30px;
            }
        }
    }

    // Facets
    // States
}
