/// Monochrome special decoration colors
///
$c--monochrome-white: #ffffff;
$c--monochrome-black: #000000;
$c--monochrome-black-gray: #222222;
$c--monochrome-dark-gray: #666666;
$c--monochrome-silver: #bfbfbf;
$c--monochrome-paper-white: #fbfbfb;
$c--monochrome-light-gray: #f2f4f7;
$c--monochrome-very-light-gray: #f8f9fb;

$monochromes: (
    'white': $c--monochrome-white,
    'black': $c--monochrome-black,
    'black-gray': $c--monochrome-black-gray,
    'dark-gray': $c--monochrome-dark-gray,
    'silver': $c--monochrome-silver,
    'light-gray': $c--monochrome-light-gray,
    'paper-white': $c--monochrome-paper-white,
    'very-light-gray': $c--monochrome-very-light-gray,
);

/// Brand specific colors
///
$c--brand-primary: #213d62;
$c--brand-secondary: #0ecc77;
$c--brand-dark-blue: #1a304e;
$c--brand-dark-green: #0baf73;
$c--brand-blue-grey: #5b728f;
$c--brand-light-blue-gray: #bfcad9;
$c--brand-very-light-blue: #e1e8f2;
$c--brand-pale-grey: #f8f9fb;

$brands: (
    'primary': $c--brand-primary,
    'secondary': $c--brand-secondary,
    'dark-blue': $c--brand-dark-blue,
    'dark-green': $c--brand-dark-green,
    'blue-grey': $c--brand-blue-grey,
    'light-blue-gray': $c--brand-light-blue-gray,
    'very-light-blue': $c--brand-very-light-blue,
    'pale-grey': $c--brand-pale-grey,
);

$c--spot-light-blue: #b4d9e3;
$c--spot-light-green: #90e1b0;
$c--spot-wheat: #fbde83;
$c--spot-salmon: #d6a19a;
$c--spot-watermelon: #e5565f;
$c--spot-light-blue-gray: #bfcad9;

$spots: (
    'light-blue': $c--spot-light-blue,
    'light-green': $c--spot-light-green,
    'wheat': $c--spot-wheat,
    'salmon': $c--spot-salmon,
    'watermelon': $c--spot-watermelon,
    'light-blue-gray': $c--spot-light-blue-gray,
);

/// Single purpose colors
///
$c--label-text: $c--brand-blue-grey;
$c--label-link: $c--brand-secondary;
$c--label-selection: null;

$labels: (
    'text': $c--label-text,
    'link': $c--label-link,
    'selection': $c--label-selection,
);

/// Signal colors
///
$c--signal-error: #e1114e;
$c--signal-success: #0ecc77;
$c--signal-disabled: #cccdcf;
$c--signal-info: $c--label-text;

$signals: (
    'error': $c--signal-error,
    'success': $c--signal-success,
    'disabled': $c--signal-disabled,
);

/// Main color map
///
$color-maps: (
    monochrome: $monochromes,
    brand: $brands,
    label: $labels,
    signal: $signals,
    spot: $spots,
);
