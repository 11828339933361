.breadcrumb-item {
    // Vars

    // Support

    // Module
    & {
        & {
            display: inline-flex;

            &:last-child {
                .breadcrumb-item--link {
                    color: $c--brand-primary;
                }

                .breadcrumb-item--icon {
                    display: none;
                }
            }
        }

        .breadcrumb-item--link {
            font-size: $fz--x-medium;
            line-height: 1;
            font-weight: $fw--normal;
            color: $c--brand-blue-grey;
            transition: color $trs--base;
            display: inline-block;

            max-width: 250px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            @include has-focus() {
                color: $c--brand-dark-blue;
            }
        }

        .breadcrumb-item--link {
            padding-top: 4px;

            @include mq('<=md') {
                padding-top: 0;
            }
        }

        .breadcrumb-item--icon {
            position: relative;
            top: 3px;
            display: inline-block;
            margin: 0 4px;
            color: $c--brand-light-blue-gray;

            @include mq('<=md') {
                top: -1px;
                margin: 0 1px 0 4px;
            }
        }
    }

    // Facets

    // States
}
