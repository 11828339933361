.conditional-select {
    // Vars

    // Support

    // Module
    & {
        .conditional-select--conditional-field {
            padding-top: 24px;
        }
    }

    // Facets

    // States
}
