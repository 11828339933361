.location {
    // Vars

    // Support

    // Module
    & {
        padding: 40px 0;

        @include mq('<=md') {
            padding: 30px 0;
        }
    }

    .location--map-col {
        @include grid--span((ratio: 12/12));
    }

    // Facets

    // States
}
