@mixin link--has-focus {
    .link--label, .link--icon {
        background-color: transparent;
        color: $c--brand-dark-green;
    }

    .link--icon {
        transform: translateX(5px);
    }
}

@mixin link--element() {
    display: block;
    cursor: pointer;
    overflow-wrap: break-word;
    font-size: unset;
    font-weight: inherit;
    line-height: unset;
    color: currentColor;
    background-color: transparent;

    border-bottom: none;

    @include has-focus() {
        color: currentColor;
        border-bottom: none;
        background-color: transparent;

        @include link--has-focus();
    }
}

@mixin link--base() {
    transition: box-shadow $trs--base, color $trs--base, background-color $trs--base;
    text-decoration: none;
    position: relative;

    color: $c--label-link;

    @include has-focus() {
        color: $c--monochrome-white;
        background-color: $c--brand-secondary;
        box-shadow: 0 -1px 0 3px $c--brand-secondary;
    }
}

@mixin link--underlined() {
    @include link--base;

    border-bottom: solid 1px $c--brand-secondary;

    @include has-focus() {
        border-bottom: solid 1px $c--brand-secondary;
    }
}

.skel {
    .u-raw-text {
        a:not(.u-btn) {
            @include link--base();
            @include link--underlined();
            font-size: $fz--btn-medium;
            line-height: 1;
        }
    }

    .link.link {
        & {
            position: relative;
            font-size: $fz--btn-medium;
            line-height: 1;

            @include has-focus() {
                background-color: transparent;
                color: $c--brand-dark-green;
            }
        }

        .link--label {
            display: inline-block;
        }

        .link--icon {
            position: relative;
            top: 3px;
            display: inline-block;
            margin-left: 6px;
            transition: transform $trs--base;
        }

        &.link__no-effects {
            position: relative;
            font-size: $fz--btn-medium;
            line-height: 1;
            color: $c--brand-secondary;

            @include has-focus() {
                background-color: transparent;
                color: $c--brand-secondary;
            }
        }

        &.link__base {
            @include link--base();
            @include link--underlined();
        }

        &.link__double-button {
            display: block;
            color: $c--monochrome-white;
            text-decoration: none;
            font-weight: $fw--semi-bold;
            border: none;
            padding: 17px 24px 16px;
            width: 100%;

            @include has-focus() {
                color: $c--monochrome-white;
            }
        }

        &.link__nba-prim-action {
            height: 100%;
        }

        &.link__element {
            @include link--element();
        }

        &.link__flex-element {
            @include link--element();
            display: flex;
        }

        &.link__meta {
            font-size: $fz--x-medium;
            font-weight: $fw--normal;
            line-height: 1;
            color: $c--brand-blue-grey;
            border-bottom: none;

            @include has-focus() {
                color: $c--brand-primary;
                border-bottom: none;
                background-color: unset;
            }
        }

        &.link__no-underline {
            border-bottom: none;

            @include has-focus() {
                border-bottom: none;
            }
        }

        &.link__icon {
            position: relative;
            display: block;
            color: $c--brand-secondary;
            text-decoration: none;

            @include ff--base-semi-bold;

            @include has-focus() {
                @include link--has-focus;
                background-color: transparent;
                color: $c--brand-dark-green;
            }

            &.link__icon-reversed {
                @include has-focus() {
                    .link--icon {
                        transform: translateX(0);
                    }
                }

                .link--icon {
                    display: inline;
                    transform: unset;
                    margin-right: 8px;
                    margin-left: 0;
                }
            }
        }
    }
}
