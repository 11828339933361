/* stylelint-disable */

#CybotCookiebotDialog {
    font-family: $ff--base-stack !important;
    font-weight: $fw--normal !important;
    color: $c--brand-blue-grey !important;
    font-style: normal !important;
    box-shadow: $shadow-level-1 !important;
    padding-top: 16px !important;
    padding-bottom: 12px !important;

    @include mq('<=sm') {
        padding: 0 24px !important;
        width: auto;
    }

    #CybotCookiebotDialogBodyContentTitle,
    .CybotCookiebotDialogDetailBodyContentCookieTypeIntro,
    #CybotCookiebotDialogBodyContentText {
        font-family: $ff--base-stack !important;
    }

    .CybotCookiebotDialogDetailBodyContentCookieTypeIntro {
        color: $c--brand-primary;
    }

    #CybotCookiebotDialogBody {
        padding-right: 0;
    }

    #CybotCookiebotDialogPoweredbyLink {
        display: none;
    }

    #CybotCookiebotDialogBodyContent, #CybotCookiebotDialogBodyButtons {
        padding-left: 0;
        margin-left: 0;
    }

    #CybotCookiebotDialogBodyContent {
        @include mq('<=sm') {
            padding-left: 0 !important;
        }
    }

    #CybotCookiebotDialogBodyButtonDecline {
        margin-left: 0;
    }

    #CybotCookiebotDialogBodyButtons {
        margin-bottom: 16px;
    }

    #CybotCookiebotDialogBodyButtonDecline {
        background: $c--brand-primary;
    }

    .CybotCookiebotDialogBodyButton {
        padding: 8px 36px;
        width: auto !important;
        border-radius: 3px;
        font-weight: $fw--semi-bold;
        font-family: $ff--base-stack !important;
    }

    #CybotCookiebotDialogBodyButtonDetails {
        color: $c--brand-secondary;
        background-image: url("#{$web-static}/img/ic_10_chevron-down-green.svg");
        background-position: right 11px;
        font-family: $ff--base-stack !important;
        font-weight: $fw--semi-bold;

        &.CybotCookiebotDialogBodyLinkExpanded {
            background-image: url("#{$web-static}/img/ic_10_chevron-up-green.svg") !important;
        }
    }

    #CybotCookiebotDialogDetailBodyContent {
        border: none;
    }

    .CybotCookiebotDialogDetailBodyContentTab,
    #CybotCookiebotDialogDetailBodyContentCookieContainerTypeDetails,
    #CybotCookiebotDialogDetailBodyContentCookieContainer {
        background: $c--brand-very-light-blue;
        border: none;
        font-family: $ff--base-stack !important;
        color: $c--brand-blue-grey !important;
    }

    .CybotCookiebotDialogDetailBodyContentTab {
        background: $c--monochrome-light-gray;
        font-weight: $fw--semi-bold;
        color: $c--brand-primary !important;
    }

    .CybotCookiebotDialogDetailBodyContentTabsItemSelected {
        background: $c--brand-very-light-blue;
    }

    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes {
        padding-top: 4px;
        background: $c--brand-very-light-blue;
    }

    .CybotCookiebotDialogDetailBodyContentCookieContainerTypesSelected,
    .CybotCookiebotDialogDetailBodyContentCookieContainerTypes {
        border: none;
        background: $c--brand-very-light-blue;
        font-family: $ff--base-stack !important;

        &:hover {
            background: $c--brand-secondary !important;
            color: $c--monochrome-white !important;
        }
    }

    .CybotCookiebotDialogDetailBodyContentCookieContainerTypesSelected {
        font-weight: $fw--semi-bold;
        background: $c--brand-secondary;
        color: $c--monochrome-white !important;
    }

    #CybotCookiebotDialogDetailBodyContentTextAbout {
        background: $c--brand-very-light-blue;
        font-family: $ff--base-stack !important;
    }

    .CybotCookiebotDialogDetailBodyContentCookieTypeTable {
        th {
            background: $c--monochrome-light-gray !important;
            padding: 5px 8px;
            border-bottom: none;
            font-family: $ff--base-stack !important;
            font-weight: $fw--semi-bold;
            color: $c--brand-primary;
        }

        tbody {
            background: $c--monochrome-white;
        }

        td {
            border: none;
            padding-left: 8px;

            &,
            a {
                font-family: $ff--base-stack !important;
                color: $c--brand-blue-grey !important;
            }

            &:nth-child(3) {
                width: 167px;
                table-layout: fixed;
            }
        }
    }

    #CybotCookiebotDialogDetailBody {
        max-width: 640px;
    }

    #CybotCookiebotDialogDetailFooter {
        padding-top: 12px;
    }
}