.double-button {
    // Vars

    $hover-reveal: 5px;
    // Support

    // Module
    & {
        & {
            border: none;
            display: inline-flex;
            overflow: hidden;
            background-color: $c--brand-secondary;
            box-shadow: none;
            transition: box-shadow $trs--base;

            @include has-focus() {
                @include shadow;

                .double-button--right {
                    &::before {
                        left: $hover-reveal;
                    }
                }
            }

            @include mq('<=sm') {
                display: block;
                background-color: transparent;
                overflow: visible;

                @include has-focus() {
                    box-shadow: none;
                }
            }
        }

        .double-button--pane {
            display: inline-block;
            justify-content: center;
            color: $c--monochrome-white;

            &::before {
                transition: left $trs--base;
            }

            @include mq('<=sm') {
                width: 100%;
                margin-bottom: 12px;
                text-align: center;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .double-button--left {
            color: $c--monochrome-white;

            @include mq('<=sm') {
                @include btn--base;
                @include btn--primary-green;
            }
        }

        .double-button--right {
            position: relative;

            @include has-focus() {
                &::before {
                    left: -$hover-reveal;
                }
            }

            @include mq('<=sm') {
                @include btn--base;
                @include btn--primary;

                &::before {
                    display: none;
                }
            }

            &::before {
                content: '';
                pointer-events: none;
                position: absolute;
                top: 0;
                left: 0;
                width: calc(100% + #{ $hover-reveal * 2 });
                height: 100%;
                background-color: $c--brand-primary;
                transform: skewX($skew);
                transform-origin: center;
            }
        }

        .double-button--content {
            z-index: z-index('double-button--content');
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            color: $c--monochrome-white;
        }
    }

    // Facets

    // States
}
