.breadcrumb-list {
    // Vars

    // Support

    // Module
    & {
        & {
            z-index: z-index('breadcrumbs');
            position: absolute;
            padding: 20px 0 24px;
            width: 100%;
        }

        .breadcrumb-list--container {
            overflow-x: auto;
            overflow-y: hidden;
        }

        .breadcrumb-list--list {
            position: relative;
            display: flex;
            overflow: visible;
            white-space: nowrap;
            padding-bottom: 8px;
        }

        .breadcrumb-list--row {
            justify-content: center;
        }

        .breadcrumb-list--col {
            @include grid--span((ratio: 12/12));
        }
    }

    // Facets
    &.breadcrumb-list__small-container {
        .breadcrumb-list--col {
            @include grid--span((ratio: 8/12));
            @include grid--span((ratio: 12/12, mq: '<=md'));
        }
    }

    // States
}
