.richtext {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0;

            @include grid--root();

            @each $value in $grid--base-container-sizes {
                @include grid--constrain($value);
            }

            @each $value in $grid--base-spacing {
                @include grid--space($value);
            }

            @each $value in $grid--base-container-sizes {
                @include grid--constrain($value);
            }

            @include mq('<=md') {
                padding: 30px 0;
            }
        }

        .richtext--row {
            justify-content: center;
        }

        .richtext--col {
            @include grid--span((ratio: 8/12));
            @include grid--span((ratio: 12/12, mq: '<=md'));

            h1, h2, h3 {
                margin-top: 60px;
                margin-bottom: 24px;

                @include mq('<=md') {
                    margin-top: 40px;
                    margin-bottom: 16px;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        p:last-child { // stylelint-disable-line selector-max-universal
            margin-bottom: 0;
        }
    }

    // Facets
    &.richtext__overlay {
        & {
            padding: 0;

            @include mq('<=md') {
                padding: 0;
            }
        }

        .richtext--container {
            max-width: unset;
        }

        .richtext--col {
            flex: unset;
        }
    }

    // States
}
