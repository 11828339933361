.video {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0;

            @include mq('<=md') {
                padding: 30px 0;
            }
        }

        .video--row {
            justify-content: center;
        }

        .video--col {
            @include grid--span((ratio: 12/12));
        }

        .video--frame-wrapper {
            position: relative;
            height: 0;
            overflow: hidden;
            padding-top: 56.25%; // Ratio 16/9
        }

        .video--frame {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    // Facets

    // States
}
