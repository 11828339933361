.lightbox-overlay-link {
    // Vars

    // Support

    // Module
    & {
        @include link--base();
    }

    // Facets

    // States
}
