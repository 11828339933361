.cookie-banner {
    // Vars

    // Support

    // Module
    & {
        & {
            z-index: z-index('cookiebanner');
            position: fixed;
            right: 24px;
            left: 24px;
            bottom: 32px;
            background-color: $c--brand-very-light-blue;
            border-radius: $sz--border-radius-base;
            color: $c--brand-blue-grey;
            padding: 12px;

            @include mq('<=md') {
                left: 12px;
                right: 12px;
                bottom: 12px;
            }

            @include shadow-active;
        }

        .cookie-banner--checkbox-col {
            overflow: hidden;
        }

        .cookie-banner--submit-col {
            display: flex;
            flex: 0 0 320px;
            justify-content: flex-end;

            @include mq('<=lg') {
                flex: 0 0 100%;
            }
        }

        .cookie-banner--description {
            @include type--copy-tertiary;
            margin-bottom: 0;
            margin-right: 12px;

            @include mq('md') {
                margin-bottom: 0;
            }

            @include mq('<=sm') {
                margin-bottom: 12px;
            }
        }

        .cookie-banner--row {
            justify-content: space-between;
            align-items: center;
        }

        .cookie-banner--text-col {
            @include grid--span((ratio: 10 / 12));
            @include grid--span((ratio: 9 / 12, mq: '<=lg'));
            @include grid--span((ratio: 12 / 12, mq: '<=sm'));
        }

        .cookie-banner--submit-col {
            @include grid--span((ratio: 2 / 12));
            @include grid--span((ratio: 3 / 12, mq: '<=lg'));
            @include grid--span((ratio: 12 / 12, mq: '<=sm'));
        }

        .cookie-banner--checkbox-list {
            display: inline-block;
        }

        .cookie-banner--submit {
            display: inline-block;
        }

        .cookie-banner--privacy-link.cookie-banner--privacy-link {
            @include type--copy-tertiary;
            margin-bottom: 0;

            @include mq('<=md') {
                margin-bottom: 4px;
            }
        }
    }

    // Facets

    // States
}
