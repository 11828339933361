.contact-form {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0;

            @include mq('<=md') {
                padding: 30px 0;
            }
        }

        .contact-form--title {
            @include type--h2;
            margin-bottom: 24px;
        }

        .contact-form--lead {
            @include type--copy;
            color: $c--brand-blue-grey;
        }

        .contact-form--row {
            justify-content: center;
        }

        .contact-form--col {
            @include grid--span((ratio: 4/12));
            @include grid--span((ratio: 8/12, mq: '<=md'));
            @include grid--span((ratio: 12/12, mq: '<=xs'));
        }

        .contact-form--buttons {
            margin-top: 30px;
        }
    }

    // Facets
    &.contact-form__overlay {
        & {
            padding: 0;

            @include mq('<=md') {
                padding: 0;
            }
        }

        .contact-form--title {
            @include mq('<=md') {
                font-size: $fz--xx-large;
            }
        }

        .contact-form--lead {
            margin-bottom: 12px;
        }

        .grid--container.grid--container {
            max-width: 100%;
        }

        .contact-form--col.contact-form--col {
            @include grid--span((ratio: 12/12));
            padding: 0 16px;
        }

        .contact-form--buttons {
            margin-top: 16px;
        }
    }

    // States
}
