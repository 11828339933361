$shadow-level-1: 0 8px 16px 0 rgba(0, 0, 0, 0.16), 0 2px 6px 0 rgba(0, 0, 0, 0.08);
$shadow-level-2: 0 12px 40px 0 rgba(0, 0, 0, 0.18);

@mixin shadow {
    box-shadow: $shadow-level-1;
}

@mixin shadow-active {
    box-shadow: $shadow-level-2;
}

@mixin shadow-page-up {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
}

@mixin shadow-page-up-active {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
}
