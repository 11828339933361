.nav-content-preview {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 20px 40px 40px;
        }

        .nav-content-preview--title {
            margin-bottom: 16px;
        }

        .nav-content-preview--image {
            width: 100%;
            height: 100%;
            max-width: 160px;
            max-height: 160px;
        }

        .nav-content-preview--description {
            font-size: $fz--small;
            line-height: $lh--small;
            margin-bottom: 24px;
        }

        .nav-content-preview--link {
            font-size: $fz--btn-medium;
            line-height: 1;
        }
    }

    // Facets

    // States
}
