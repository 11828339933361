.nav-panel-meta {
    // Vars

    // Support

    // Module
    & {
        & {
            background-color: $c--brand-dark-blue;
            padding: 32px 0;
            border-top: solid 1px $c--brand-blue-grey;
        }

        .nav-panel-meta--lang-list {
            display: flex;
            padding-bottom: 12px;
        }

        .nav-panel-meta--list-item {
            display: flex;
            padding: 12px 0;
        }

        .nav-panel-meta--link {
            color: $c--brand-light-blue-gray;
            font-size: $fz--x-medium;
            line-height: 1;

            @include has-focus() {
                color: $c--monochrome-white;
            }
        }
    }

    // Facets

    // States
}
