&.navigation-entry__mobile-nav {
    // Vars

    // Support

    // Module
    & {
        & {
            &:first-child {
                margin-top: 18px;
            }
        }

        .navigation-entry--label {
            color: $c--brand-light-blue-gray;

            @include has-focus() {
                color: $c--monochrome-white;
            }
        }

        .navigation-entry--label.navigation-entry--label__active {
            color: $c--brand-secondary;
        }
    }

    // Facets

    // States
}
