@mixin type--lead {
    font-size: $fz--lead;
    line-height: $lh--lead;
    font-weight: $fw--normal;
    margin-bottom: 24px;
    color: $c--brand-blue-grey;

    @include mq('<=md') {
        margin-bottom: 16px;
    }
}

@mixin type--copy {
    font-size: $fz--copy;
    line-height: $lh--copy;
    margin-bottom: 24px;
    font-weight: $fw--normal;

    @include mq('<=md') {
        margin-bottom: 16px;
    }
}

@mixin type--copy-secondary {
    font-size: $fz--copy-secondary;
    line-height: $lh--copy-secondary;
    font-weight: $fw--normal;
    margin-bottom: 24px;

    @include mq('<=md') {
        margin-bottom: 16px;
    }
}

@mixin type--copy-tertiary {
    font-size: $fz--copy-tertiary;
    line-height: $lh--copy-tertiary;
    margin-bottom: 24px;
    font-weight: $fw--normal;

    @include mq('<=md') {
        margin-bottom: 16px;
    }
}

.skel {
    .lead {
        @include type--lead;
    }

    .highlight {
        color: $c--brand-secondary;
    }

    .copy {
        & {
            @include type--copy;
            color: $c--brand-blue-grey;
        }

        &.copy__secondary {
            @include type--copy-secondary;
            color: $c--brand-blue-grey;
        }

        &.copy__tertiary {
            @include type--copy-tertiary;
            color: $c--brand-blue-grey;
        }
    }
}

@import './content-types/links';
