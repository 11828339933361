@mixin type--h1 {
    @include ff--base-semi-bold;
    color: $c--brand-primary;
    font-size: 4.4rem;
    line-height: 1.14;
    margin-bottom: 24px;

    @include mq('<=md') {
        font-size: 3.2rem;
        line-height: 1.06;
        margin-bottom: 16px;
    }
}

@mixin type--h2 {
    @include ff--base-semi-bold;
    color: $c--brand-primary;
    font-size: 3.2rem;
    line-height: 1.06;
    margin-bottom: 24px;

    @include mq('<=md') {
        font-size: 2.4rem;
        line-height: 1.08;
        margin-bottom: 16px;
    }
}

@mixin type--h3 {
    @include ff--base-bold;
    color: $c--brand-primary;
    font-size: 2.4rem;
    line-height: 1.08;
    margin-bottom: 24px;

    @include mq('<=md') {
        font-size: 2.4rem;
        line-height: 1.08;
        margin-bottom: 16px;
    }
}

@mixin type--h4 {
    @include ff--base-regular;
    color: $c--brand-primary;
    font-size: 1.8rem;
    margin-bottom: 24px;

    @include mq('<=md') {
        margin-bottom: 16px;
    }
}

@mixin type--h5 {
    @include ff--base-regular;
    color: $c--brand-primary;
    font-size: 1.8rem;
    margin-bottom: 24px;

    @include mq('<=md') {
        margin-bottom: 16px;
    }
}

@mixin type--h6 {
    @include ff--base-regular;
    color: $c--brand-primary;
    font-size: 1.8rem;
    margin-bottom: 24px;

    @include mq('<=md') {
        margin-bottom: 16px;
    }
}
