.nba-primary {
    // Vars

    // Support

    // Module
    & {
        padding: 40px 0;
        height: 100%;
        min-height: 360px;
        background-color: $c--brand-primary;

        @include mq('<=sm') {
            padding-top: 30px;
        }

        .nba-primary--container.nba-primary--container {
            overflow: visible;
        }

        .nba-primary--row.nba-primary--row {
            background-color: $c--brand-very-light-blue;
            border-radius: $sz--border-radius-base;
            margin-left: 0;
            margin-right: 0;
            overflow: hidden;
            box-shadow: none;
            transition: box-shadow $trs--base;

            @include mq('>md') {
                @include has-focus() {
                    @include shadow-active;
                }
            }

            position: relative;

            @include mq('<=md') {
                display: flex;
                background-color: transparent;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                @include at-root(html, '.ie10') {
                    display: block;
                }
                //overflow: visible;
            }
        }

        .nba-primary--background.nba-primary--background {
            content: '';
            //z-index: z-index('nba-primary-background');
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            @include grid--span((ratio: 12/12));

            @include mq('>md') {
                background-image: linear-gradient(100deg, transparent 50%, $c--monochrome-white 50%);
                background-size: calc(100% + #{ $hover-reveal * 2 }) 100%;
                background-position: -$hover-reveal 0;
                transition: background-position $trs--base;
            }
        }

        .nba-primary--content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
        }

        .nba-primary--content-wrapper.nba-primary--content-wrapper {
            z-index: z-index('nba-primary-content');
            display: flex;

            @include grid--span((ratio: 6/12));
            @include grid--span((ratio: 12/12, mq: '<=md'));
            @include grid--span((ratio: 3/4, mq: '<=sm'));
            @include grid--span((ratio: 4/4, mq: '<=xs'));

            @include at-root(html, '.ie10') {
                margin-left: auto;
                margin-right: auto;
            }

            @include mq('>md') {
                padding: 0;
            }

            @include mq('<=md') {
                margin-bottom: 32px;
                border-radius: $sz--border-radius-base;
            }

            @include mq('<=sm') {
                margin-bottom: 24px;
                padding: 0;
            }

            &:last-child {
                display: flex;
                justify-content: flex-end;

                @include mq('<=md') {
                    margin-bottom: 0;
                }
            }
        }

        .nba-primary--content-wrapper-left {
            @include mq('md') {
                background-color: $c--brand-very-light-blue;
            }

            @include has-focus() {
                & ~ .nba-primary--background {
                    background-position: 0 0;
                }
            }
        }

        .nba-primary--content-wrapper-right {
            @include mq('md') {
                background-color: $c--monochrome-white;
            }

            @include has-focus() {
                & ~ .nba-primary--background {
                    background-position: -#{$hover-reveal * 2} 0;
                }
            }
        }

        .nba-primary--col {
            @include grid--span((ratio: 6/12));
        }
    }

    // Facets

    // States
}
