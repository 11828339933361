.list {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0;

            @include mq('<=md') {
                padding: 30px 0;
            }
        }

        .list--list {
            width: 100%;
            list-style: none;
        }

        .list--row {
            justify-content: center;
        }

        .list--col {
            @include grid--span((ratio: 8/12));
            @include grid--span((ratio: 12/12, mq: '<=md'));
        }
    }

    // Facets

    // States
}
