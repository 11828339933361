.article {
    // Vars

    // Support

    // Module
    & {
        .article--row {
            justify-content: center;
        }

        .article--col {
            @include grid--span((ratio: 8/12));
        }
    }

    // Facets

    // States
}
