.payment-solution-overview {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 40px 0;

            @include mq('<=md') {
                padding: 30px 0;
            }
        }

        .payment-solution-overview--title {
            @include type--h2;
            margin-bottom: 0;

            @include mq('<=md') {
                margin-bottom: 0;
            }
        }

        .payment-solution-overview--row {
            @include mq('<=sm') {
                justify-content: center;
            }
        }

        .payment-solution-overview--category {
            margin-top: 24px;

            @include grid--span((ratio: 4/12));
            @include grid--span((ratio: 6/12, mq: '<=md'));
            @include grid--span((ratio: 3/4, mq: '<=sm'));
            @include grid--span((ratio: 4/4, mq: '<=xs'));
        }
    }

    // Facets

    // States
}
